import api from 'config/api';
import { SET_PARTNERS, SET_AGENT_PARTNER } from '../actionTypes';

export const getPartners = () => dispatch => {
  return api.get('partners/').then(({ data }) => {
    const dataNormalized = data.data.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

    return dispatch({
      type: SET_PARTNERS,
      payload: {
        ...data,
        data: dataNormalized,
      },
    });
  });
};

export const getAgentPartner =
  ({ partner }) =>
  dispatch => {
    return api.get(`partners/${partner}`).then(({ data }) => {
      return dispatch({
        type: SET_AGENT_PARTNER,
        payload: data,
      });
    });
  };
