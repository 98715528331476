import React from 'react';

const Courses = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6279 2.34882C11.8585 2.21706 12.1415 2.21706 12.3721 2.34882L22.8721 8.34882C23.1058 8.48235 23.25 8.73086 23.25 9C23.25 9.26914 23.1058 9.51765 22.8721 9.65118L12.3721 15.6512C12.1415 15.7829 11.8585 15.7829 11.6279 15.6512L1.1279 9.65118C0.894215 9.51765 0.75 9.26914 0.75 9C0.75 8.73086 0.894215 8.48235 1.1279 8.34882L11.6279 2.34882ZM3.01167 9L12 14.1362L20.9883 9L12 3.86381L3.01167 9Z"
        fill="#222428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 8.25C22.9142 8.25 23.25 8.58579 23.25 9V17.25C23.25 17.6642 22.9142 18 22.5 18C22.0858 18 21.75 17.6642 21.75 17.25V9C21.75 8.58579 22.0858 8.25 22.5 8.25ZM4.5 11.25C4.5 10.8358 4.83579 10.5 5.25 10.5H18.75C19.1642 10.5 19.5 10.8358 19.5 11.25V17.25C19.5 17.5224 19.3523 17.7733 19.1142 17.9056L12.3762 21.649C12.2656 21.7132 12.1371 21.75 12 21.75C11.8629 21.75 11.7344 21.7132 11.6238 21.649L4.88577 17.9056C4.64767 17.7733 4.5 17.5224 4.5 17.25V11.25ZM12.75 19.7254V15C12.75 14.5858 12.4142 14.25 12 14.25C11.5858 14.25 11.25 14.5858 11.25 15V19.7254L6 16.8087V12H18V16.8087L12.75 19.7254Z"
        fill="#222428"
      />
    </svg>
  );
};

export default Courses;
