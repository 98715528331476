import api from 'config/api';
import { SET_PERMISSIONS, SET_PROFILE } from '../actionTypes';
import isUserHasPermissions from 'utils/isUserHasPermissions';

export const getProfile = () => dispatch => {
  return api.get('users/profile').then(({ data }) => {
    return dispatch({
      type: SET_PROFILE,
      payload: data,
    });
  });
};

export const setPermissions =
  ({ user }) =>
  dispatch => {
    const isAdmin = isUserHasPermissions({ rolesAllowed: ['admin'], user });
    const isAgent = isUserHasPermissions({ rolesAllowed: ['agent'], user });
    return dispatch({
      type: SET_PERMISSIONS,
      payload: {
        isAdmin,
        isAgent,
      },
    });
  };
