import React from 'react';
import styles from './RadioButton.module.scss';
import global from '../UIGeneral.module.scss';

const RadioButton = ({ label, name, radioGroup, value, groupValue, line, onChange = () => {} }) => {
  return (
    <div
      className={`
    ${radioGroup ? styles.radioWrp : global.wrp}
    ${line ? styles.inLine : styles.inRow}
  `}
    >
      <input
        checked={groupValue === value}
        onChange={onChange}
        className={styles.radio}
        type="radio"
        id={value}
        name={name}
        value={value}
      />
      <label className={styles.label} htmlFor={value}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
