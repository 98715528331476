import React from 'react';
import styles from './TableLoading.module.scss';

const TableLoading = ({ colspan }) => {
  return (
    <tr>
      <td colSpan={colspan}>
        <div className={styles.tableLoading}>
          <div className={styles.spinner} />
          Loading
        </div>
      </td>
    </tr>
  );
};

export default TableLoading;
