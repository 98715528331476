import React, { useState } from 'react';
import styles from './TableFilter.module.scss';
import Filter from 'components/svg/Filter';
import ShowFilter from './ShowFilter';

const TableFilter = ({ pageSearch, field, type, updateSearchInURL }) => {
  const [visibleFilter, setVisibleFilter] = useState(false);
  if (field.key !== 'status' && field.key !== 'type') {
    return null;
  }

  const onCancelFilter = () => {
    setVisibleFilter(false);
  };

  const onConfirmFilter = () => {
    setVisibleFilter(false);
  };

  const onShowFilter = e => {
    setVisibleFilter(true);
  };

  return (
    <>
      <button className={styles.icon} onClick={onShowFilter}>
        <Filter />
      </button>
      {visibleFilter && (
        <ShowFilter
          field={field}
          type={type}
          pageSearch={pageSearch}
          updateSearchInURL={updateSearchInURL}
          onConfirm={onConfirmFilter}
          onCancel={onCancelFilter}
        />
      )}
    </>
  );
};

export default TableFilter;
