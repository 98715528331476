import React, { useEffect, useState } from 'react';
import { Input, RadioGroup, DatePicker, Button, RadioButton, Select, ImagesInput } from 'components/ui';
import api from 'config/api';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import { date, dateISO } from 'utils/date';
import { useHistory } from 'react-router-dom';

const PartnerForm = ({ partnerData }) => {
  const id = partnerData.id;
  const isNew = !id;
  const history = useHistory();

  const [saving, setSaving] = useState(false);

  const types = [
    {
      value: 'custom',
      text: 'Індывідуальны',
    },
    {
      value: 'dominance',
      text: 'Панаванне',
    },
    {
      value: 'big_step',
      text: 'Вялікі крок',
    },
    {
      value: 'affordable',
      text: 'Можна сабе дазволіць',
    },
    {
      value: 'dream',
      text: 'Запаветная мара',
    },
    {
      value: 'stay_alive',
      text: 'Далучайся!',
    },
  ];
  const [form, setForm] = useState({
    name: '',
    nameTag: '',
    url: '',
    emailDomain: '',
    status: 'draft',
    logo: '',
    subscriptionType: 'custom',
    subscriptionStart: '',
    subscriptionEnd: '',
  });

  useEffect(() => {
    setForm(partnerData);
  }, [partnerData.id]);

  const setFormValue = (value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const updateFormAfterSave = data => {
    const dataFromServer = pick(data, [
      'name',
      'url',
      'nameTag',
      'emailDomain',
      'status',
      'logo',
      'subscriptionType',
      'subscriptionStart',
      'subscriptionEnd',
    ]);
    setForm({
      ...dataFromServer,
      subscriptionStart: date(dataFromServer.subscriptionStart),
      subscriptionEnd: date(dataFromServer.subscriptionEnd),
    });
  };

  const onSubmit = () => {
    setSaving(true);
    const afterSave = () => {
      toast.success(isNew ? 'Партнёр створаны' : 'Партнёр захаваны');
    };

    const dataToSend = {
      ...form,
      subscriptionStart: dateISO(form.subscriptionStart),
      subscriptionEnd: dateISO(form.subscriptionEnd),
    };

    isNew
      ? api
          .post(`partners`, dataToSend)
          .then(data => {
            if (data?.data?.id) {
              history.push(`/partners/${data?.data?.id}`);
            }
            afterSave();
          })
          .finally(() => setSaving(false))
      : api
          .patch(`partners/${id}`, dataToSend)
          .then(afterSave)
          .finally(() => setSaving(false));
  };

  return (
    <>
      <Input label="Назва парнёра*" value={form.name} onChange={e => setFormValue(e, 'name')} />
      <Input label="Адмысловае імя" value={form.nameTag} onChange={e => setFormValue(e, 'nameTag')} />
      <Input label="Url" value={form.url} onChange={e => setFormValue(e, 'url')} />
      <Input label="Email дамен" value={form.emailDomain} onChange={e => setFormValue(e, 'emailDomain')} />

      <Select
        label="Тып"
        options={types}
        value={form.subscriptionType}
        onChange={e => setFormValue(e, 'subscriptionType')}
      />

      <RadioGroup
        line={true}
        name="status"
        label="Статус"
        value={form.status}
        onChange={e => setFormValue(e, 'status')}
      >
        <RadioButton label="актыўны" value="active" />
        <RadioButton label="не актыўны" value="inactive" />
      </RadioGroup>

      <DatePicker
        value={form.subscriptionStart}
        label="Пачатак падпіскі"
        onChange={e => setFormValue(e, 'subscriptionStart')}
      />

      <DatePicker
        value={form.subscriptionEnd}
        label="Заканчэнне падпіскі"
        onChange={e => setFormValue(e, 'subscriptionEnd')}
      />

      <ImagesInput
        type={'single'}
        label="Лагатып"
        value={form.logo}
        onChange={e => setFormValue(e, 'logo')}
      />

      <div className="d-flex flex-end">
        <Button loading={saving} onClick={onSubmit} text={isNew ? 'Стварыць' : 'Захаваць'} />
      </div>
    </>
  );
};

export default PartnerForm;
