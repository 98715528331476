import React, { useState } from 'react';
// import styles from './ConfirmModal.module.scss';
import global from 'components/ui/UIGeneral.module.scss';
import { Button, Checkbox } from 'components/ui';
import ElementsBlock from 'components/ui/elementsBlock/ElementsBlock';
import t from 'utils/t';

const TypeCourseModal = ({ onConfirm, onCancel, pageSearch, updateSearchInURL }) => {
  const filter = pageSearch.get('filter[type]')?.split(',');
  const [form, setForm] = useState({
    certification: filter?.includes('certification'),
    educational: filter?.includes('educational'),
  });

  const setFormValue = (value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onConfirmLocal = () => {
    const newValue = [];
    Object.entries(form).forEach(item => {
      if (item[1]) {
        newValue.push(item[0]);
      }
    });
    pageSearch.set('filter[type]', newValue);
    updateSearchInURL();
    onConfirm();
  };

  return (
    <>
      <div className={global.modalOverlay} onClick={onCancel} />
      <div className={global.modal}>
        <div className={global.modalTitle}>Фільтр</div>
        <div className={global.modalBody}>
          <ElementsBlock className={'d-flex flex-flow-wrap mb-0 flex-column'}>
            <Checkbox
              name="certification"
              label={t('certification')}
              value={form.certification}
              onChange={e => setFormValue(e, 'certification')}
            />
            <Checkbox
              name="educational"
              label={t('educational')}
              value={form.educational}
              onChange={e => setFormValue(e, 'educational')}
            />
          </ElementsBlock>
        </div>
        <div className={global.modalActions}>
          <Button type="button" text={'Ок'} onClick={onConfirmLocal} />
        </div>
      </div>
    </>
  );
};

export default TypeCourseModal;
