import PagePanel from 'components/pagePanel/PagePanel';
import BackButton from 'components/backButton/BackButton';
import ListTypes from './list-types/List-types';
import TaskForm from './TaskForm';
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/ui';
import api from '../../config/api';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Plus from 'components/svg/Plus';
import Trash from 'components/svg/Trash';
import ConfirmModal from 'components/confirmModal/ConfirmModal';

const TaskList = () => {
  const history = useHistory();
  const [type, setType] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (id !== 'create') {
      api.get(`tasks/${id}`).then(({ data }) => {
        setData(data);
        setType(data.type);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id]);

  const createTask = () => {
    setType(null);
    setData(null);
    history.push(`/tasks/create`);
  };

  const onDelete = () => {
    api.delete(`tasks/${id}`).then(() => {
      toast.success('Заданне выдалена');
      history.goBack();
    });
  };

  const beforeBack = () => {
    setData(null);
  };

  const backAction = () => {
    setData(null);
    history.goBack();
  };

  if (loading) return false;
  return (
    <>
      <PagePanel>
        <BackButton beforeBack={beforeBack} backAction={backAction} />
        <div style={{ display: 'flex' }}>
          {type && (
            <Button onClick={createTask} className={'mr-8'} text={'Стварыць іншае заданне'}>
              <Plus />
            </Button>
          )}
          {id !== 'create' && (
            <Button color="Red" onClick={() => setShowConfirm(true)} text={'Выдаліць'}>
              <Trash />
            </Button>
          )}
        </div>
      </PagePanel>
      <div className="page">
        {!type && <ListTypes onSetType={setType} />}

        {type && <TaskForm type={type} data={data} onSetType={setType} />}
      </div>
      {showConfirm && (
        <ConfirmModal
          onCancel={() => setShowConfirm(false)}
          onConfirm={onDelete}
          text="Вы ўпэўнены, што выдаляем?"
        />
      )}
    </>
  );
};

export default TaskList;
