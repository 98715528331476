import React from 'react';
import styles from './Textarea.module.scss';
import global from '../UIGeneral.module.scss';

const Textarea = ({ error = '', label, value, className, noWrap, height = '150px', onChange = () => {} }) => {
  const onChangeLocal = event => {
    onChange(event.target.value);
  };

  const body = () => {
    return (
      <>
        {label && <label className={global.label}>{label}</label>}
        <textarea
          className={styles.textarea}
          style={{
            height,
          }}
          value={value || ''}
          onChange={onChangeLocal}
        />
        <span className={global.error}>{error}</span>
      </>
    );
  };

  return noWrap ? body() : <div className={`${className} ${global.wrp}`}>{body()}</div>;
};

export default Textarea;
