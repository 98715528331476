import React from 'react';
// import stylesDatePicker from './DatePicker.module.scss';
import 'react-day-picker/lib/style.css';
import global from '../UIGeneral.module.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { date } from 'utils/date';
import { Input } from 'components/ui';

const LABELS = {
  nextMonth: 'наступны месяц',
  previousMonth: 'папярэдні месяц',
};

const WEEKDAYS_LONG = ['Нядзеля', 'Панядзелак', 'Аўторак', 'Серада', 'Чацвер', 'Пятніца', 'Субота'];

const WEEKDAYS_SHORT = ['пн', 'аўт', 'сер', 'чац', 'пт', 'сб', 'ндз'];

const MONTHS = [
  'Студзень',
  'Люты',
  'Сакавік',
  'Красавік',
  'Травень',
  'Чэрвень',
  'Ліпень',
  'Жнівень',
  'Верасень',
  'Кастрычнік',
  'Лістапад',
  'Снежань',
];

function parseDate(str) {
  const dateData = str.split('.');
  const parsedDate = new Date(`${dateData[1]}/${dateData[0]}/${dateData[2]} `);
  if (!isNaN(parsedDate.getTime())) {
    return new Date(parsedDate);
  }
}

function formatDate(dateData, format) {
  return date(dateData);
}

const DatePicker = ({ error = '', label, value, onChange = () => {} }) => {
  const onChangeLocal = event => {
    onChange(date(event));
  };
  return (
    <div className={global.wrp}>
      <label className={global.label}>{label}</label>
      <div>
        <DayPickerInput
          value={value}
          onDayChange={onChangeLocal}
          placeholder=""
          formatDate={formatDate}
          parseDate={parseDate}
          inputProps={{ ref: null }}
          component={props => <Input noWrap={true} type="datePicker" readOnly {...props} />}
          dayPickerProps={{
            labels: LABELS,
            weekdaysLong: WEEKDAYS_LONG,
            weekdaysShort: WEEKDAYS_SHORT,
            months: MONTHS,
            firstDayOfWeek: 0,
          }}
        />
        <span className={global.error}>{error}</span>
      </div>
    </div>
  );
};

export default DatePicker;
