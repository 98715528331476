import { Route, Redirect } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import React from 'react';
import ContentBar from './contentBar/СontentBar';
import useTitle from '../hooks/useTitle';
import isUserHasPermissions from '../utils/isUserHasPermissions';

const PrivateRoute = ({ showSearchContentBar, pageTitle, rolesAllowed, children, user, ...rest }) => {
  useTitle(pageTitle);

  if (
    !isUserHasPermissions({
      rolesAllowed,
      user,
    })
  ) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={() => (
        <>
          <Navigation />
          <div className="pageContent">
            <ContentBar showSearchContentBar={showSearchContentBar} />
            {children}
          </div>
        </>
      )}
    />
  );
};

export default PrivateRoute;
