const properWordEnd = (count, words) => {
  if (count === 0) return words[0];

  const teen = count > 10 && count < 20;
  const endsWithOne = count % 10 === 1;

  if (!teen && endsWithOne) {
    return words[1];
  }
  if (!teen && count % 10 >= 2 && count % 10 <= 4) {
    return words[2];
  }

  return words[3];
};

export default properWordEnd;
