import { combineReducers } from 'redux';
import app from './app';
import profile from './profile';
import partners from './partners';
import nav from './nav';

const createReducer = combineReducers({
  app,
  profile,
  partners,
  nav,
});

export default createReducer;
