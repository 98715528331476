// 0 things
// things count ends with 1
// things count ends with 2-4
// things count ends with 5-9,  0 and teens (10-19)

const tasksWord = ['таскаў', 'таск', 'таскі', 'таскаў'];
const modulesWord = ['модуляў', 'модуль', 'модуля', 'модуляў'];
const coursesWord = ['курсаў', 'курс', 'курса', 'курсаў'];
const areaWord = ['сфер', 'сфера', 'сферы', 'сфер'];
const partnersWord = ['партнёраў', 'партнёр', 'партнёра', 'партнёраў'];
const usersWord = ['карыстальнікаў', 'карыстальнік', 'карыстальніка', 'карыстальнікаў'];
const certificatesWord = ['сертыфікатаў', 'сертыфікат', 'сертыфіката', 'сертыфікатаў'];

export { tasksWord, modulesWord, coursesWord, areaWord, partnersWord, usersWord, certificatesWord };
