import React from 'react';
import { date } from 'utils/date';
import t from 'utils/t';
import styles from './User.module.scss';

const UserStatistic = ({ userData, certificates }) => {
  return (
    <>
      <div className={styles.userInfoRow}>
        <span className={styles.userInfoRowTitle}>Email: </span>
        {userData?.email}
      </div>
      <div className={styles.userInfo}>
        {userData?.partnerInfo?.name && (
          <div className={styles.userInfoRow}>
            <span className={styles.userInfoRowTitle}>Партнёр: </span>
            {userData?.partnerInfo?.name}
          </div>
        )}
        <div className={styles.userInfoRow}>
          <span className={styles.userInfoRowTitle}>ID: </span>
          {userData?.id}
        </div>
        <div className={styles.userInfoRow}>
          <span className={styles.userInfoRowTitle}>Створаны: </span>
          {date(userData?.createdAt)}
        </div>
        <div className={styles.userInfoRow}>
          <span className={styles.userInfoRowTitle}>Абноўлены: </span>
          {date(userData?.updatedAt)}
        </div>
        {userData?.lastCourseFinishDate && (
          <div className={styles.userInfoRow}>
            <span className={styles.userInfoRowTitle}>Апошні курс скончыў:</span>{' '}
            {date(userData?.lastCourseFinishDate)}
          </div>
        )}
      </div>
      {!!certificates.length && (
        <>
          <div className={styles.tableTitle}>Сертыфікаты</div>
          <table className={`${styles.table} ${styles.certificatesTable} mainTable`}>
            <tbody>
              <tr className="mainTableHead">
                <td>Назва</td>
                <td>Створаны</td>
              </tr>
              {certificates.map((row, index) => (
                <tr key={index}>
                  <td>{row.area.name}</td>
                  <td>{date(row.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {!!userData.stats?.courses.length && (
        <>
          <div className={styles.tableTitle}>Курсы</div>
          <table className={`${styles.table} mainTable`}>
            <tbody>
              <tr className="mainTableHead">
                <td>Назва</td>
                <td>Вынік</td>
                <td>Статус</td>
                <td>Тып</td>
                <td>Завершаны</td>
              </tr>
              {userData.stats?.courses.map((row, index) => (
                <tr key={index}>
                  <td>{row.name}</td>
                  <td>{row.rate}</td>
                  <td>{t(row.status)}</td>
                  <td>{t(row.type)}</td>
                  <td>{date(row.completedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {!!userData.stats?.submodules.length && (
        <>
          <div className={styles.tableTitle}>Модулі</div>
          <table className={`${styles.table} mainTable`}>
            <tbody>
              <tr className="mainTableHead">
                <td>Назва</td>
                <td>Вынік</td>
                <td>Статус</td>
                <td>Завершаны</td>
              </tr>
              {userData.stats?.submodules.map((row, index) => (
                <tr key={index}>
                  <td>{row.name}</td>
                  <td>{row.rate}</td>
                  <td>{t(row.status)}</td>
                  <td>{date(row.completedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default UserStatistic;
