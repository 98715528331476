import React, { useState } from 'react';
// import styles from './ConfirmModal.module.scss';
import tableStyles from 'components/table/Table.module.scss';
import global from 'components/ui/UIGeneral.module.scss';
import { Button, Checkbox } from 'components/ui';
import ElementsBlock from 'components/ui/elementsBlock/ElementsBlock';
import config from 'pages/task/configs';

const TypeTaskModal = ({ onConfirm, onCancel, pageSearch, updateSearchInURL }) => {
  const filter = pageSearch.get('filter[type]')?.split(',');
  const [form, setForm] = useState({
    answer_question_aloud_multiple: filter?.includes('answer_question_aloud_multiple'),
    repeat_sentence: filter?.includes('repeat_sentence'),
    dictation: filter?.includes('dictation'),
    answer_question_aloud: filter?.includes('answer_question_aloud'),
    highlight_incorrect: filter?.includes('highlight_incorrect'),
    listen_phonetics: filter?.includes('listen_phonetics'),
    read_aloud: filter?.includes('read_aloud'),
    list_items_aloud: filter?.includes('list_items_aloud'),
    memorize_typical_answer: filter?.includes('memorize_typical_answer'),
    listen_phonetics_choice: filter?.includes('listen_phonetics_choice'),
    listen_choose_picture: filter?.includes('listen_choose_picture'),
  });

  const setFormValue = (value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onConfirmLocal = () => {
    const newValue = [];
    Object.entries(form).forEach(item => {
      if (item[1]) {
        newValue.push(item[0]);
      }
    });
    pageSearch.set('filter[type]', newValue);
    updateSearchInURL();
    onConfirm();
  };

  return (
    <>
      <div className={global.modalOverlay} onClick={onCancel} />
      <div className={global.modal}>
        <div className={global.modalTitle}>Фільтр</div>
        <div className={global.modalBody}>
          <ElementsBlock className={'d-flex flex-flow-wrap mb-0 flex-column'}>
            {[
              'answer_question_aloud_multiple',
              'repeat_sentence',
              'dictation',
              'answer_question_aloud',
              'highlight_incorrect',
              'listen_phonetics',
              'read_aloud',
              'list_items_aloud',
              'memorize_typical_answer',
              'listen_phonetics_choice',
              'listen_choose_picture',
            ].map(item => (
              <Checkbox
                name={item}
                label={
                  <span
                    className={tableStyles.typeText}
                    style={{
                      background: config[item].bgInTable,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    {config[item].nameShort}
                  </span>
                }
                value={form[item]}
                onChange={e => setFormValue(e, item)}
              />
            ))}
          </ElementsBlock>
        </div>
        <div className={global.modalActions}>
          <Button type="button" text={'Ок'} onClick={onConfirmLocal} />
        </div>
      </div>
    </>
  );
};

export default TypeTaskModal;
