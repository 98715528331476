import React from 'react';
// import styles from './ConfirmModal.module.scss';
import global from 'components/ui/UIGeneral.module.scss';
import { Button } from '../ui';

const ConfirmModal = ({ text, onConfirm, onCancel }) => {
  return (
    <>
      <div className={global.modalOverlay} onClick={onCancel} />}
      <div className={global.modal}>
        <div className={global.modalTitle}>Трэба падцвердзіць</div>
        <div className={global.modalBody}>{text}</div>
        <div className={global.modalActions}>
          <Button type="button" text={'Не'} color={'RedOutline'} className={'mr-8'} onClick={onCancel} />
          <Button type="button" text={'Так'} onClick={onConfirm} />
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
