import React from 'react';
import { Button } from '../index';
import styles from './ImagesInput.module.scss';
import global from '../UIGeneral.module.scss';
import Pencil from '../../svg/Pencil';
import api from '../../../config/api';
import { toast } from 'react-toastify';

const ImagesInput = ({ value, type = 'multiple', label, limit, onChange }) => {
  if (type === 'single') {
    limit = 1;
  }
  const valuesToRender = (type === 'multiple' ? value : value && { 1: value }) || {};

  const deleteItem = index => {
    let newValue = '';
    if (type === 'multiple') {
      newValue = { ...value };
      delete newValue[index];
    }
    onChange(newValue);
  };
  const handleChange = (e, index) => {
    const file = e?.target?.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      api
        .post(`media/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          if (type === 'multiple') {
            onChange({
              ...value,
              [index]: res.data.url,
            });
          } else {
            onChange(res.data.url);
          }
        })
        .catch(() => {
          toast.error('Памылка загрузкі Малюнка', {
            autoClose: 5000,
          });
        });
    }
  };
  return (
    <div className={global.wrp}>
      {label && <label className={global.label}>{label}</label>}
      <div className={styles.imagesWrp}>
        {Object.entries(valuesToRender).map((item, index) => {
          return (
            <div className={styles.imageWrp} key={'image' + index}>
              <img className={styles.image} src={item[1]} alt="" />
              <div className={styles.imageButtons}>
                <div className={styles.imageChange}>
                  <div className={styles.imagePencil}>
                    {' '}
                    <Pencil />{' '}
                  </div>
                  <input
                    className={styles.imageFile}
                    type="file"
                    onChange={e => handleChange(e, index + 1)}
                  />
                </div>
                <button className={styles.deleteItem} type="button" onClick={() => deleteItem(item[0])}>
                  X
                </button>
              </div>
            </div>
          );
        })}
        {(!limit || Object.keys(valuesToRender).length < limit) && (
          <div className={styles.imageRow} key={'image'}>
            <div className={styles.imageNewWrp}>
              <input
                className={styles.imageFile}
                type="file"
                onChange={e => handleChange(e, Object.keys(valuesToRender).length + 1)}
              />
              <Button text="Дадаць" className={styles.imageNew} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagesInput;
