import { SET_PARTNERS } from '../actionTypes';
const initialState = [];

const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PARTNERS:
      return { ...state, ...payload } || initialState;

    default:
      return state;
  }
};

export default usersReducer;
