export const fields = [
  {
    name: 'name',
    default: '',
    label: 'Назва задання*',
    component: 'Input',
  },
  {
    name: 'tip',
    default: '',
    label: 'Падказка',
    component: 'Input',
  },
  {
    name: 'status',
    default: 'draft',
    label: 'Статус',
    component: 'Radio',
    options: [
      {
        label: 'чарнавік',
        value: 'draft',
      },
      {
        label: 'апублікаваны',
        value: 'published',
      },
    ],
  },
];
