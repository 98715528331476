import React from 'react';
import styles from './ListDND.module.scss';
import SortArrow from 'components/svg/Arrow';
const TableHeader = ({ tableConfig, sortHandle, orderBy, sortBy }) => {
  return (
    <>
      {Object.values(tableConfig).map(field => {
        const show = field.sortable && field.key === sortBy;
        return (
          <div
            style={{ width: field.width }}
            key={field.key}
            onClick={() => field.sortable && sortHandle(field.key)}
            className={`
                ${styles.mainTable_TD} 
                ${styles[`mainTable_TD_${field.class}`]}
                ${show && (orderBy === 'asc' ? styles.asc : styles.desc)}
              `}
          >
            {field.title}
            {
              <SortArrow
                style={{
                  opacity: show ? 1 : 0,
                }}
              />
            }
          </div>
        );
      })}
    </>
  );
};

export default TableHeader;
