export const APP_TOGGLE_MENU = 'APP_TOGGLE_MENU';
export const SET_PROFILE = 'SET_PROFILE';

export const GET_USERS = 'GET_USERS';

export const SET_NAV = 'SET_NAV';

export const SET_PARTNERS = 'SET_PARTNERS';
export const SET_AGENT_PARTNER = 'SET_AGENT_PARTNER';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
