import React from 'react';
import styles from './Select.module.scss';
import global from '../UIGeneral.module.scss';

const Select = ({ textField, valueField, label, onChange, value, options, className = global.wrp }) => {
  const onChangeLocal = event => {
    onChange(event.target.value);
  };
  return (
    <div className={className}>
      {label && <label className={global.label}>{label}</label>}
      <select className={styles.select} onChange={onChangeLocal} value={value}>
        {options.map(item => (
          <option value={item[valueField || 'value']} key={item[valueField || 'value']}>
            {item[textField || 'text']}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
