import { pick } from 'lodash';

export const name = 'Размова: Адказаць на пытанне ўголас - некалькі варыянтаў';
export const nameShort = 'Адказаць на пытанне ўголас - н.в.';
export const bgInTable = '#6495ED';

export const fields = [
  {
    name: 'correctAnswers',
    default: [],
    label: 'Правільныя адказы',
    component: 'ListNewValues',
  },
  {
    name: 'afterTaskExplanation',
    default: '',
    label: 'Тлумачэнне задання',
    component: 'Textarea',
  },
  {
    name: 'textContent',
    default: '',
    label: 'Змест задання',
    component: 'Input',
  },
  {
    name: 'minimumCorrectAnswers',
    default: 0,
    label: 'Мінімум правільных адказаў*',
    component: 'Input',
    inputType: 'number',
  },
];

export const dataToSend = form => {
  return {
    additionalData: {
      afterTaskExplanation: form.afterTaskExplanation,
      minimumCorrectAnswers: form.minimumCorrectAnswers,
      textContent: form.textContent,
    },
    type: 'answer_question_aloud_multiple',
    name: form.name,
    tip: form.tip,
    status: form.status,
    correctAnswers: form.correctAnswers,
  };
};

export const parseData = data => {
  const dataFast = pick(data, ['name', 'status', 'tip', 'correctAnswers']);
  const { afterTaskExplanation, minimumCorrectAnswers, textContent } = data.additionalData;
  return {
    ...dataFast,
    afterTaskExplanation,
    minimumCorrectAnswers,
    textContent,
  };
};
