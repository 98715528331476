import React from 'react';
import styles from './Switcher.module.scss';
import global from '../UIGeneral.module.scss';

const Switcher = ({ label, name, value, className, insideBlock, onChange = () => {} }) => {
  const onChangeLocal = event => {
    onChange(event.target.checked);
  };
  return (
    <div className={`${insideBlock ? 'mb-8' : global.wrp} ${className}`}>
      <input
        checked={value}
        onChange={onChangeLocal}
        className={styles.checkbox}
        type="checkbox"
        value={value}
        id={name}
      />
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default Switcher;
