import React, { useEffect, useState } from 'react';
import styles from './Table.module.scss';
import api from 'config/api';
import SortArrow from 'components/svg/Arrow';

import TableLoading from 'components/tableLoading/TableLoading';
import TableFilter from 'components/table/tableFilter/TableFilter';
import Pagination from '../pagination/Pagination';
import { date } from 'utils/date';
import config from 'pages/task/configs';
import { SET_NAV } from '../../store/actionTypes';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../utils/t';
import lodashGet from 'lodash.get';

const showSort = (field, pageSearch) => {
  let urlSort = pageSearch.get('sort');
  if (urlSort?.startsWith('-')) {
    urlSort = urlSort.replace('-', '');
  }
  return field.sortable && field.key === urlSort;
};

const Table = ({ onRowClick, apiPath, tableConfig, type }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const partnersFromStore = useSelector(({ partners }) => partners);

  const pageSearch = new URLSearchParams(window.location.search);
  if (!pageSearch.get('limit')) {
    pageSearch.set('limit', '50');
  }

  useEffect(() => {
    if (type !== 'partners') {
      api
        .get(
          `${apiPath}?${pageSearch
            .toString()
            .replace('offset', 'page[offset]')
            .replace('limit', 'page[limit]')}`,
        )
        .then(({ data }) => {
          setData(data.data);
          setLoading(false);
          setTotal(data.meta.total);
        });
    } else {
      setData(Object.values(partnersFromStore.data || {}));
      setLoading(false);
      setTotal(partnersFromStore.meta.total);
    }
  }, [apiPath, pageSearch.toString()]);

  useEffect(() => {
    if (type === 'courses' || type === 'areas') {
      pageSearch.set('filter[status]', 'draft,published');
    }
    if (type === 'partners') {
      pageSearch.set('filter[status]', 'active,inactive');
    }
  }, []);

  const updateSearchInURL = () => {
    history.push({
      search: pageSearch.toString(),
    });
    dispatch({
      type: SET_NAV,
      payload: {
        page: type,
        path: pageSearch.toString(),
      },
    });
  };

  const sortHandle = key => {
    const sort = pageSearch.get('sort');
    if (sort === key) {
      sort.startsWith('-') ? pageSearch.set('sort', sort.substring(1)) : pageSearch.set('sort', `-${sort}`);
    } else {
      pageSearch.set('sort', key);
    }
    pageSearch.set('offset', '0');
    updateSearchInURL();
  };

  const onOffsetChange = newOffset => {
    pageSearch.set('offset', newOffset);
    updateSearchInURL();
  };

  const onLimitChange = newLimit => {
    pageSearch.set('limit', newLimit);
    updateSearchInURL();
    onOffsetChange(0);
  };

  const formatTd = ({ fieldInstance, field }) => {
    if (fieldInstance.format === 'date') return date(field[fieldInstance.key]);

    if (fieldInstance.key === 'status') {
      const statusColor = status => {
        switch (status) {
          case 'inactive':
          case 'draft':
            return '#cccccc';
          case 'active':
          case 'published':
            return '#199f6b';
          default:
            return null;
        }
      };

      return (
        <span className={styles.typeText} style={{ background: statusColor(field[fieldInstance.key]) }}>
          {t(field[fieldInstance.key])}
        </span>
      );
    }

    if (type === 'tasks' && fieldInstance.key === 'type') {
      return (
        <span className={styles.typeText} style={{ background: config[field[fieldInstance.key]]?.bgInTable }}>
          {config[field[fieldInstance.key]]?.nameShort}
        </span>
      );
    }

    if (type === 'courses' && fieldInstance.key === 'type') {
      const statusColor = status => {
        switch (status) {
          case 'certification':
            return '#9bccfc';
          case 'educational':
            return '#c393fa';
        }
      };
      return (
        <span className={styles.typeText} style={{ background: statusColor(field[fieldInstance.key]) }}>
          {t(field[fieldInstance.key])}
        </span>
      );
    }
    if (type === 'users' && fieldInstance.key === 'partner') {
      return lodashGet(partnersFromStore.data, field['partner'])?.name;
    }
    return lodashGet(field, fieldInstance.key);
  };
  return (
    <>
      <div className={styles.mainTableTop}>
        <Pagination
          total={total}
          offset={Number(pageSearch.get('offset'))}
          limit={Number(pageSearch.get('limit'))}
          onOffsetChange={onOffsetChange}
          onLimitChange={onLimitChange}
        />
      </div>
      <div className="tableWrp">
        <table className="mainTable">
          <tbody>
            <tr className="mainTableHead">
              {Object.values(tableConfig).map(field => {
                return (
                  <td
                    key={field.key}
                    className={`${
                      showSort(field, pageSearch) && pageSearch.get('sort')?.startsWith('-') ? 'asc' : 'desc'
                    }`}
                  >
                    <div>
                      <TableFilter
                        field={field}
                        pageSearch={pageSearch}
                        type={type}
                        updateSearchInURL={updateSearchInURL}
                      />
                      <div className={styles.sortWrp} onClick={() => field.sortable && sortHandle(field.key)}>
                        {field.title}
                        {
                          <SortArrow
                            style={{
                              opacity: `${showSort(field, pageSearch) ? 1 : 0}`,
                            }}
                          />
                        }
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
            {loading ? (
              <TableLoading colspan={Object.values(tableConfig).length} />
            ) : (
              data.map(field => (
                <tr className="pointer" key={field.id} onClick={() => onRowClick(field)}>
                  {Object.values(tableConfig).map((fieldInstance, index) => (
                    <td key={field['id'] + index}>{formatTd({ field, fieldInstance })}</td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className={styles.mainTableBottom}>
        <Pagination
          total={total}
          offset={Number(pageSearch.get('offset'))}
          limit={Number(pageSearch.get('limit'))}
          onOffsetChange={onOffsetChange}
          onLimitChange={onLimitChange}
        />
      </div>
    </>
  );
};

export default Table;
