import React from 'react';

const Filter = ({ className }) => {
  return (
    <svg
      className={className}
      enableBackground="new 0 0 36 30"
      height="30px"
      version="1.1"
      viewBox="0 0 36 30"
      width="36px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon fill="#54667a" points="14,30 22,25 22,17 35.999,0 17.988,0 0,0 14,17 " />
    </svg>
  );
};

export default Filter;
