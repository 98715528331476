import axios from 'axios';
import { toast } from 'react-toastify';

const token = localStorage.getItem('token');

const api = axios.create({
  baseURL: process?.env?.REACT_APP_BASE_URL,
  headers: {
    ...(token && { Authorization: `Bearer ${token}` }),
  },
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.status === 401) {
      toast.error('Памылка 401');
    }
    if (error.response?.status === 500) {
      toast.error('Памылка 500');
    }
    if (error.response?.status === 400) {
      error.response.data.message.forEach(message => {
        toast.error(message, {
          autoClose: 5000,
        });
      });
    }
    return Promise.reject(error);
  },
);

export default api;
