import React from 'react';

const Modules = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 21V23H11V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V6H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H13ZM4 19H20V8H4V19ZM13 10H18V12H13V10ZM13 14H18V16H13V14ZM9 10V13H12C12 13.5933 11.8241 14.1734 11.4944 14.6667C11.1648 15.1601 10.6962 15.5446 10.1481 15.7716C9.59987 15.9987 8.99667 16.0581 8.41473 15.9424C7.83279 15.8266 7.29824 15.5409 6.87868 15.1213C6.45912 14.7018 6.1734 14.1672 6.05764 13.5853C5.94189 13.0033 6.0013 12.4001 6.22836 11.8519C6.45542 11.3038 6.83994 10.8352 7.33329 10.5056C7.82664 10.1759 8.40666 10 9 10ZM2 3H22V5H2V3Z"
        fill="#222428"
      />
    </svg>
  );
};

export default Modules;
