import React from 'react';
import styles from './RadioGroup.module.scss';
import global from '../UIGeneral.module.scss';

const RadioGroup = ({ label, children, line = false, value, name, className, onChange = () => {} }) => {
  const onChangeChild = e => {
    onChange(e.target.value);
  };
  return (
    <div className={`${global.wrp} ${className}`}>
      <label className={global.label}>{label}</label>
      <div className={line ? styles.inLine : styles.inRow}>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            onChange: onChangeChild,
            name,
            line,
            groupValue: value,
            radioGroup: true,
          });
        })}
      </div>
    </div>
  );
};

export default RadioGroup;
