import React from 'react';
import styles from './ElementsBlock.module.scss';
// import global from "../UIGeneral.module.scss";

const ElementBlocks = ({ children, className }) => {
  return (
    <div className={`${styles.wrp} ${className}`}>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          insideBlock: true,
        });
      })}
    </div>
  );
};

export default ElementBlocks;
