import * as answer_question_aloud_multiple from './answer_question_aloud_multiple.js';
import * as repeat_sentence from './repeat_sentence.js';
import * as dictation from './dictation.js';
import * as answer_question_aloud from './answer_question_aloud.js';
import * as highlight_incorrect from './highlight_incorrect.js';
import * as listen_phonetics from './listen_phonetics.js';
import * as read_aloud from './read_aloud.js';
import * as list_items_aloud from './list_items_aloud.js';
import * as memorize_typical_answer from './memorize_typical_answer.js';
import * as listen_phonetics_choice from './listen_phonetics_choice.js';
import * as listen_choose_picture from './listen_choose_picture.js';

const taskConfig = {
  answer_question_aloud_multiple: answer_question_aloud_multiple,
  repeat_sentence: repeat_sentence,
  dictation: dictation,
  answer_question_aloud: answer_question_aloud,
  highlight_incorrect: highlight_incorrect,
  listen_phonetics: listen_phonetics,
  read_aloud: read_aloud,
  list_items_aloud: list_items_aloud,
  memorize_typical_answer: memorize_typical_answer,
  listen_phonetics_choice: listen_phonetics_choice,
  listen_choose_picture: listen_choose_picture,
};

export default taskConfig;
