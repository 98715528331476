import React, { useEffect, useState } from 'react';
import styles from './App.module.scss';
import { Home, User, TablePage, Task, Course, Area, Module, Partner, Profile, Company } from 'pages';
import { toast, ToastContainer } from 'react-toastify';
import PrivateRoute from 'components/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { tasks, submodules, users, courses, areas, partners } from 'config/pages';
import { SET_PROFILE } from '../store/actionTypes';
import api from '../config/api';
import { Button } from '../components/ui';
import { getProfile, setPermissions } from '../store/actions/profile';
import { getPartners, getAgentPartner } from '../store/actions/partners';

function App() {
  const dispatch = useDispatch();
  const [tokenLoading, setTokenLoading] = useState(true);
  const { userFromStore, isAdmin, isAgent } = useSelector(({ profile }) => ({
    userFromStore: profile,
    isAdmin: profile.isAdmin,
    isAgent: profile.isAgent,
  }));

  const { isLoading, error, isAuthenticated, user, logout, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const logoutHandle = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    const getToken = async () => {
      const accessToken = await getAccessTokenSilently().catch(e => {
        toast.error(e, {
          autoClose: 5000,
        });
      });
      api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
      setTokenLoading(false);
      if (accessToken) {
        dispatch({
          type: SET_PROFILE,
          payload: { token: accessToken },
        });
      }
    };

    getToken();
    if (isAuthenticated) {
      dispatch({
        type: SET_PROFILE,
        payload: user,
      });
      dispatch(setPermissions({ user }));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userFromStore.token) {
      dispatch(getProfile());
    }
  }, [userFromStore.token, tokenLoading]);

  useEffect(() => {
    if (userFromStore.token) {
      if (isAdmin) {
        dispatch(getPartners());
      }
      if (isAgent && userFromStore.partner) {
        dispatch(getAgentPartner({ partner: userFromStore.partner }));
      }
    }
  }, [userFromStore.token, userFromStore.partner]);

  if (error) {
    return <div className={styles.loginWrp}>Памылка аўтарызацыі {error.message}</div>;
  }

  if (isLoading || tokenLoading) {
    return <div className={styles.loginWrp}>Аўтарызацыя...</div>;
  }

  if (!isAuthenticated) {
    return (
      <div className={styles.loginWrp}>
        <Button text="Увайсці" className={styles.loginButton} onClick={loginWithRedirect} />
      </div>
    );
  }

  if (!userFromStore.id) {
    return <div className={styles.loginWrp}>Загрузка профілю...</div>;
  }

  if (!isAgent && !isAdmin) {
    return (
      <div className={styles.loginWrp}>
        <div className={styles.logoutBox}>
          <div className={styles.logoutBox_text}>Не дастаткова правоў каб увайсці</div>
          <Button text="Выйсці" className={styles.loginButton} onClick={logoutHandle} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.contentWrp}>
      <Router>
        <Switch>
          <PrivateRoute
            rolesAllowed={['admin', 'agent']}
            user={user}
            exact
            path="/users"
            pageTitle={'Карыстальнікі'}
          >
            <TablePage {...users({ isAdmin })} type="users" />
          </PrivateRoute>
          <PrivateRoute
            rolesAllowed={['admin', 'agent']}
            user={user}
            showSearchContentBar={false}
            pageTitle={'Карыстальнікі'}
            exact
            path="/users/:id"
          >
            <User />
          </PrivateRoute>
          <PrivateRoute rolesAllowed={['admin']} user={user} pageTitle={'Заданні'} exact path="/tasks">
            <TablePage {...tasks} type="tasks" />
          </PrivateRoute>
          <PrivateRoute
            user={user}
            rolesAllowed={['admin']}
            pageTitle={'Заданні'}
            showSearchContentBar={false}
            exact
            path="/tasks/:id"
          >
            <Task />
          </PrivateRoute>
          <PrivateRoute rolesAllowed={['admin']} user={user} pageTitle={'Курсы'} exact path="/courses">
            <TablePage {...courses} type="courses" />
          </PrivateRoute>
          <PrivateRoute
            rolesAllowed={['admin']}
            user={user}
            pageTitle={'Курсы'}
            showSearchContentBar={false}
            exact
            path="/courses/:id"
          >
            <Course />
          </PrivateRoute>
          <PrivateRoute rolesAllowed={['admin']} user={user} pageTitle={'Модулі'} exact path="/modules">
            <TablePage {...submodules} type="modules" />
          </PrivateRoute>
          <PrivateRoute
            rolesAllowed={['admin']}
            user={user}
            pageTitle={'Модулі'}
            showSearchContentBar={false}
            exact
            path="/modules/:id"
          >
            <Module />
          </PrivateRoute>
          <PrivateRoute rolesAllowed={['admin']} user={user} pageTitle={'Сферы'} exact path="/areas">
            <TablePage {...areas} type="areas" />
          </PrivateRoute>
          <PrivateRoute
            rolesAllowed={['admin']}
            user={user}
            pageTitle={'Сферы'}
            showSearchContentBar={false}
            exact
            path="/areas/:id"
          >
            <Area />
          </PrivateRoute>
          <PrivateRoute rolesAllowed={['admin']} user={user} pageTitle={'Партнёры'} exact path="/partners">
            <TablePage {...partners} type="partners" />
          </PrivateRoute>
          <PrivateRoute
            rolesAllowed={['admin']}
            user={user}
            pageTitle={'Партнёры'}
            showSearchContentBar={false}
            exact
            path="/partners/:id"
          >
            <Partner />
          </PrivateRoute>
          <PrivateRoute user={user} pageTitle={'Профіль'} showSearchContentBar={false} path="/profile">
            <Profile />
          </PrivateRoute>
          <PrivateRoute user={user} pageTitle={'Кампанія'} showSearchContentBar={false} path="/company">
            <Company />
          </PrivateRoute>
          <PrivateRoute showSearchContentBar={false} user={user} pageTitle={'Галоўная'} path="/">
            <Home />
          </PrivateRoute>
        </Switch>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
