import React from 'react';
import styles from './Button.module.scss';

const Button = ({ disabled, color = 'Red', text, loading, onClick, children, className }) => {
  const reallyDisabled = loading || disabled;
  return (
    <button
      disabled={reallyDisabled}
      onClick={onClick}
      type="button"
      className={`
        ${styles.button}
        ${styles['button' + color]} 
        ${loading && styles.buttonLoading}
        ${reallyDisabled && styles.disabled}
        ${className}
      `}
    >
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          className: styles.icon,
        });
      })}
      <span className={styles.buttonText}>{text}</span>
      {loading && <span className="spinner" />}
    </button>
  );
};

export default Button;
