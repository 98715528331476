import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  RadioButton,
  RadioGroup,
  ListNewValues,
  Textarea,
  ImagesInput,
  AudioInput,
  Select,
} from '../../components/ui';
import { toast } from 'react-toastify';
import api from '../../config/api';
import config from './configs';
import * as configGeneral from './configs/__general';
import { useHistory } from 'react-router-dom';
import taskConfig from 'pages/task/configs';

const initialState = type => {
  const fields = [...configGeneral.fields, ...config[type].fields];

  return fields.reduce((acc, el) => {
    acc[el.name] = el.default;
    return acc;
  }, {});
};

const TaskForm = ({ data, type, onSetType }) => {
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState(initialState(type));
  const history = useHistory();
  useEffect(() => {
    if (data?.id) {
      setForm(config[type].parseData(data));
    }
  }, [data?.id, type]);

  const selectOptions = Object.keys(taskConfig).map(task => {
    return {
      text: taskConfig[task].name,
      value: task,
    };
  });

  const setFormValue = (value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    setSaving(true);
    const afterSave = () => {
      toast.success(data?.id ? 'Заданне захавана' : 'Заданне створана');
    };

    const dataToSend = config[type].dataToSend(form);

    data?.id
      ? api
          .patch(`tasks/${data.id}`, dataToSend)
          .then(afterSave)
          .finally(() => setSaving(false))
      : api
          .post(`tasks`, dataToSend)
          .then(data => {
            if (data?.data?.id) {
              history.push(`/tasks/${data?.data?.id}`);
            }
            afterSave();
          })
          .finally(() => setSaving(false));
  };
  return (
    <>
      <Select label="Тып задання" options={selectOptions} onChange={onSetType} value={type} />
      {[...configGeneral.fields, ...config[type].fields].map((field, index) => {
        switch (field.component) {
          case 'Input':
            return (
              <Input
                key={index}
                type={field.inputType || 'text'}
                label={field.label}
                value={form[field.name]}
                onChange={e => setFormValue(e, field.name)}
              />
            );
          case 'Radio':
            return (
              <RadioGroup
                line={true}
                key={index}
                className={'width-50'}
                name={field.name}
                label="Статус"
                value={form[field.name]}
                onChange={e => setFormValue(e, field.name)}
              >
                {field.options.map((option, index) => (
                  <RadioButton key={index} label={option.label} value={option.value} />
                ))}
              </RadioGroup>
            );
          case 'ListNewValues':
            return (
              <ListNewValues
                key={index}
                label={field.label}
                list={form[field.name]}
                setValue={e => setFormValue(e, field.name)}
              />
            );
          case 'Textarea':
            return (
              <Textarea
                key={index}
                label={field.label}
                value={form[field.name]}
                onChange={e => setFormValue(e, field.name)}
              />
            );
          case 'ImagesInput':
            return (
              <ImagesInput
                key={index}
                label={field.label}
                value={form[field.name]}
                onChange={e => setFormValue(e, field.name)}
              />
            );
          case 'AudioInput':
            return (
              <AudioInput
                key={index}
                label={field.label}
                value={form[field.name]}
                onChange={e => setFormValue(e, field.name)}
              />
            );
        }
      })}

      <div className="d-flex flex-end">
        <Button loading={saving} onClick={onSubmit} text={data?.id ? 'Захаваць' : 'Стварыць'} />
      </div>
    </>
  );
};

export default TaskForm;
