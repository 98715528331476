import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './ListDND.module.scss';
import React, { useEffect, useState } from 'react';
import global from '../UIGeneral.module.scss';
import api from '../../../config/api';
import Pagination from '../../pagination/Pagination';
import TableHeader from './TableHeader';
import { Input, Select } from '../index';
import Filter from 'components/svg/Filter';
import { Link } from 'react-router-dom';
import t from 'utils/t';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  if (droppableSource.droppableId === 'VALUE') {
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);
  }

  if (droppableSource.droppableId === 'LIST') {
    sourceClone[droppableSource.index].disabled = true;

    destClone.splice(droppableDestination.index, 0, sourceClone[droppableSource.index]);
  }

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 0,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'rgba(173,216,230,0.5)' : 'white',
  // padding: 8
});

const ListDND = ({ apiPath, value, onChange, label, tableConfig, type }) => {
  const [showFilter, setShowFilter] = useState(false);

  const [list, setList] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [orderBy, setOrderBy] = useState('asc');
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    api
      .get(`${apiPath}?sort=${orderBy === 'desc' ? '-' : ''}${sortBy}&page[limit]=30&page[offset]=${offset}`)
      .then(({ data }) => {
        const dataArray = data.data;
        if (dataArray) {
          const valueAsObject = value.reduce((acc, el) => {
            acc[el.id] = el;
            return acc;
          }, {});

          const listItemsWithDisabled = dataArray.map(item => {
            if (valueAsObject[item.id]) {
              item.disabled = true;
            }
            return item;
          });
          setList(listItemsWithDisabled);
        }
        setTotal(data?.meta?.total);
      });
  }, [apiPath, sortBy, orderBy, offset, value]);

  const sortHandle = key => {
    if (sortBy === key) {
      orderBy === 'asc' ? setOrderBy('desc') : setOrderBy('asc');
    } else {
      setSortBy(key);
      setOrderBy('asc');
    }
    setOffset(0);
  };

  const onOffsetChange = newOffset => {
    setOffset(newOffset);
  };

  const getList = id => {
    switch (id) {
      case 'LIST':
        return list;
      case 'VALUE':
        return value;
      default:
        return null;
    }
  };

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    if (source.droppableId === 'LIST' && destination.droppableId === 'LIST') {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(getList(source.droppableId), source.index, destination.index);

      if (source.droppableId === 'VALUE') {
        onChange(items);
      }

      setList(items);
    } else {
      const result = move(getList(source.droppableId), getList(destination.droppableId), source, destination);
      setList(result.LIST || []);
      onChange(result.VALUE || []);
    }
  };

  const onDeleteFromList = index => {
    const deletedValue = value.splice(index, 1)[0];
    const listLocal = [...list];
    let valueInList = listLocal.find(item => item.id === deletedValue.id);

    if (valueInList) {
      valueInList.disabled = false;
    }
    setList(listLocal);

    onChange(value || []);
  };

  const types = [
    {
      value: 'custom',
      text: 'Індывідуальны',
    },
    {
      value: 'dominance',
      text: 'Панаванне',
    },
    {
      value: 'big_step',
      text: 'Вялікі крок',
    },
    {
      value: 'affordable',
      text: 'Можна сабе дазволіць',
    },
    {
      value: 'dream',
      text: 'Запаветная мара',
    },
    {
      value: 'stay_alive',
      text: 'Далучайся!',
    },
  ];
  return (
    <div className={global.wrp}>
      <label className={global.label}>{label}</label>

      {showFilter && (
        <div className={styles.filterBody}>
          <Select
            label="Статус"
            options={types}
            onChange={() => {}}
            // value={form.subscriptionType}
          />
          <Select
            label="Тып"
            options={types}
            onChange={() => {}}
            // value={form.subscriptionType}
          />
        </div>
      )}
      <div className={styles.listDND_header}>
        <div className={styles.column}>
          <Input
            hideErrors={true}
            noWrap={true}
            placeholder={'Пошук'}
            // value={form.name}
            // onChange={(e)=>setFormValue(e,'name')}
          />
          <div
            onClick={() => setShowFilter(!showFilter)}
            className={`${showFilter && styles.filterOpened} ${styles.filter}`}
          >
            <Filter />
          </div>
          <Pagination total={total} offset={offset} limit={30} hideLimit onOffsetChange={onOffsetChange} />
        </div>

        <div className={styles.column} />
      </div>
      <div className={styles.listDND}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={styles.column}>
            <Droppable droppableId="LIST">
              {(provided, snapshot) => (
                <div className={styles.mainTable}>
                  <div className={styles.mainTableHead}>
                    <TableHeader
                      tableConfig={tableConfig}
                      sortHandle={sortHandle}
                      orderBy={orderBy}
                      sortBy={sortBy}
                    />
                  </div>
                  <div
                    className={styles.dropZone}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {list.map((item, index) => (
                      <Draggable
                        disableInteractiveElementBlocking={false}
                        isDragDisabled={item.disabled}
                        key={item.id}
                        draggableId={item.id + '_'}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <div
                              className={styles.mainTable_TR}
                              key={item.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, {
                                ...provided.draggableProps.style,
                                transform: snapshot.isDragging
                                  ? provided.draggableProps.style?.transform
                                  : 'translate(0px, 0px)',
                                opacity: item.disabled ? 0.7 : 1,
                              })}
                            >
                              {Object.values(tableConfig).map((fieldInstance, index) => (
                                <div
                                  key={item['id'] + index}
                                  className={`${styles.mainTable_TD} ${
                                    styles[`mainTable_TD_${fieldInstance.class}`]
                                  }`}
                                >
                                  {index === 0 ? (
                                    <Link target="_blank" to={`/${type}/${item.id}`}>
                                      {t(item[fieldInstance.key])}
                                    </Link>
                                  ) : (
                                    <span>{t(item[fieldInstance.key])}</span>
                                  )}
                                </div>
                              ))}
                            </div>
                            {snapshot.isDragging && (
                              <div
                                key={'drag_' + item['id']}
                                className={styles.mainTable_TR}
                                style={getItemStyle(snapshot.isDragging, {
                                  ...provided.draggableProps.style,
                                  transform: 'none !important',
                                  position: 'static !important',
                                })}
                              >
                                {Object.values(tableConfig).map((fieldInstance, index) => (
                                  <div
                                    className={`${styles.mainTable_TD} ${
                                      styles[`mainTable_TD_${fieldInstance.class}`]
                                    }`}
                                  >
                                    {t(item[fieldInstance.key])}
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      </Draggable>
                    ))}
                  </div>
                </div>
              )}
            </Droppable>
          </div>
          <div className={styles.between}>
            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill="#B8B8B8" />
              <path d="M3.99 17H16V15H3.99V12L0 16L3.99 20V17Z" fill="#B8B8B8" />
            </svg>
          </div>
          <Droppable droppableId="VALUE">
            {(provided, snapshot) => (
              <div
                className={styles.column}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div className={styles.mainTable}>
                  <div className={styles.mainTableHead}>
                    {Object.values(tableConfig).map(field => {
                      return (
                        <div
                          key={field['key']}
                          className={`${styles.mainTable_TD} ${styles[`mainTable_TD_${field.class}`]}`}
                        >
                          {field.title}
                        </div>
                      );
                    })}
                    <div className={`${styles.mainTable_TD} ${styles.TD_deleteBTN}`} />
                  </div>
                  {value.map((item, index) => (
                    <Draggable isDragDisabled={true} key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className={styles.mainTable_TR}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          {Object.values(tableConfig).map((fieldInstance, index) => (
                            <div
                              className={`${styles.mainTable_TD} ${
                                styles[`mainTable_TD_${fieldInstance.class}`]
                              }`}
                              key={item['id'] + index}
                            >
                              {index === 0 ? (
                                <Link target="_blank" to={`/${type}/${item.id}`}>
                                  {t(item[fieldInstance.key])}
                                </Link>
                              ) : (
                                <span>{t(item[fieldInstance.key])}</span>
                              )}
                            </div>
                          ))}
                          <div className={`${styles.mainTable_TD} ${styles.TD_deleteBTN}`}>
                            <button className={styles.deleteBTN} onClick={() => onDeleteFromList(index)}>
                              x
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={styles.listDND_header}>
        <div className={styles.column}>
          <Pagination total={total} offset={offset} limit={30} hideLimit onOffsetChange={onOffsetChange} />
        </div>
        <div className={styles.column} />
      </div>
    </div>
  );
};

export default ListDND;
