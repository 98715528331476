import { Button } from 'components/ui';
import React from 'react';
import styles from './ListTypes.module.scss';
import config from 'pages/task/configs';
import taskConfig from 'pages/task/configs';

const ListTypes = ({ onSetType }) => {
  return (
    <>
      {Object.keys(taskConfig).map(item => (
        <Button className={styles.button} text={config[item].name} onClick={() => onSetType(item)} />
      ))}
    </>
  );
};

export default ListTypes;
