import React from 'react';

const Home = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1834 11.836L13.1271 2.78443L12.5201 2.1774C12.3818 2.04 12.1948 1.96289 11.9998 1.96289C11.8048 1.96289 11.6178 2.04 11.4795 2.1774L1.81621 11.836C1.67449 11.9772 1.56248 12.1453 1.4868 12.3305C1.41112 12.5156 1.37331 12.7141 1.37559 12.9141C1.38496 13.7391 2.07168 14.3977 2.89668 14.3977H3.89277V22.0313H20.1068V14.3977H21.124C21.5248 14.3977 21.9021 14.2407 22.1857 13.9571C22.3254 13.8179 22.436 13.6524 22.5113 13.4701C22.5865 13.2879 22.6248 13.0925 22.624 12.8954C22.624 12.4969 22.467 12.1196 22.1834 11.836ZM13.3123 20.3438H10.6873V15.5626H13.3123V20.3438ZM18.4193 12.7102V20.3438H14.8123V15.0001C14.8123 14.4821 14.3928 14.0626 13.8748 14.0626H10.1248C9.60684 14.0626 9.1873 14.4821 9.1873 15.0001V20.3438H5.58027V12.7102H3.33027L12.0021 4.04537L12.5436 4.58678L20.6717 12.7102H18.4193Z"
        fill="black"
      />
    </svg>
  );
};

export default Home;
