import { APP_TOGGLE_MENU } from '../actionTypes';

const initialState = {
  menuOpened: true,
};

const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case APP_TOGGLE_MENU:
      return {
        ...state,
        menuOpened: !state.menuOpened,
      };

    default:
      return state;
  }
};

export default appReducer;
