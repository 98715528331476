import { useHistory, useParams } from 'react-router-dom';
import PagePanel from 'components/pagePanel/PagePanel';
import BackButton from 'components/backButton/BackButton';
import React, { useEffect, useState } from 'react';
import { Input, RadioGroup, ListDND, Button, RadioButton, ImagesInput } from 'components/ui';
import api from 'config/api';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import { partners } from 'config/pages';
import ConfirmModal from '../components/confirmModal/ConfirmModal';
import Trash from '../components/svg/Trash';

const Area = () => {
  const history = useHistory();
  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);
  const isNew = id === 'create';

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [form, setForm] = useState({
    name: '',
    nameTag: '',
    status: 'draft',
    sortIndex: 0,
    image: '',
    partners: [],
  });

  const setFormValue = (value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!isNew) {
      api.get(`areas/${id}`).then(({ data }) => {
        setForm(pick(data, ['name', 'sortIndex', 'nameTag', 'image', 'status', 'partners']));
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [isNew, id]);

  const onSubmit = () => {
    const formToSend = { ...form };
    if (!formToSend.image) {
      delete formToSend.image;
    }

    const partners = form.partners.map(item => item.id);
    setSaving(true);
    const afterSave = () => {
      toast.success(isNew ? 'Сфера створана' : 'Сфера захавана');
    };

    const dataToSend = {
      ...formToSend,
      partners,
    };

    isNew
      ? api
          .post(`areas`, dataToSend)
          .then(data => {
            if (data?.data?.id) {
              history.push(`/areas/${data?.data?.id}`);
            }
            afterSave();
          })
          .finally(() => setSaving(false))
      : api
          .patch(`areas/${id}`, dataToSend)
          .then(afterSave)
          .finally(() => setSaving(false));
  };

  const onDelete = () => {
    api.delete(`areas/${id}`).then(() => {
      toast.success('Сфера выдалена');
      history.goBack();
    });
  };

  if (loading) return false;

  return (
    <>
      <PagePanel>
        <BackButton />
        {id !== 'create' && (
          <Button color="Red" onClick={() => setShowConfirm(true)} text={'Выдаліць'}>
            <Trash />
          </Button>
        )}
      </PagePanel>
      <div className="page">
        <Input label="Назва сферы*" value={form.name} onChange={e => setFormValue(e, 'name')} />
        <Input label="Тэхнічнае імя" value={form.nameTag} onChange={e => setFormValue(e, 'nameTag')} />
        <Input
          type={'number'}
          label="Індэкс"
          value={form.sortIndex}
          onChange={e => setFormValue(e, 'sortIndex')}
        />
        <RadioGroup
          line={true}
          name="status"
          label="Статус"
          value={form.status}
          onChange={e => setFormValue(e, 'status')}
        >
          <RadioButton label="чарнавік" value="draft" />
          <RadioButton label="апублікаваны" value="published" />
        </RadioGroup>

        <ImagesInput
          type={'single'}
          label="Малюнак"
          value={form.image}
          onChange={e => setFormValue(e, 'image')}
        />

        <ListDND
          type="partners"
          label="Партнёры"
          apiPath={partners.apiPath}
          tableConfig={partners.tableConfigDND}
          value={form.partners}
          onChange={value => setFormValue(value, 'partners')}
        />

        <div className="d-flex flex-end">
          <Button loading={saving} onClick={onSubmit} text={isNew ? 'Стварыць' : 'Захаваць'} />
        </div>
      </div>

      {showConfirm && (
        <ConfirmModal
          onCancel={() => setShowConfirm(false)}
          onConfirm={onDelete}
          text="Вы ўпэўнены, што выдаляем?"
        />
      )}
    </>
  );
};

export default Area;
