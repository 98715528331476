import styles from '../Home.module.scss';
import properWordEnd from 'utils/words-ends';
import { useEffect, useState } from 'react';
import api from 'config/api';

import { coursesWord, certificatesWord, usersWord } from 'utils/wordForm';

const AgentDashboard = ({ id }) => {
  const [data, setData] = useState({});
  useEffect(() => {
    api.get(`/partners/${id}/stats`).then(({ data }) => {
      setData(data);
    });
  }, [id]);

  return (
    <>
      <div className={styles.totalItem}>
        <p>{data.usersCount}</p>
        <div>{properWordEnd(data.usersCount, usersWord)}</div>
      </div>
      <div className={styles.totalItem}>
        <p>{data.certificatesCount}</p>
        <div>{properWordEnd(data.certificatesCount, certificatesWord)}</div>
      </div>
      <div className={styles.totalItem}>
        <p>{data.passedEducationalCoursesCount}</p>
        <div>навучальных {properWordEnd(data.passedEducationalCoursesCount, coursesWord)}</div>
      </div>
    </>
  );
};

export default AgentDashboard;
