import React from 'react';
import { date } from 'utils/date';

import { useSelector } from 'react-redux';
const Profile = () => {
  const profile = useSelector(({ profile }) => profile);

  return (
    <div className="page">
      Email: {profile.email}
      <br />
      Імя: {profile.given_name}
      <br />
      Нікнэйм: {profile.nickname}
      <br />
      Абноўлены: {date(profile.updated_at)}
      <br />
    </div>
  );
};

export default Profile;
