import { SET_NAV } from '../actionTypes';
const initialState = JSON.parse(window.localStorage.getItem('nav')) || {};
const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NAV:
      const newNav = {
        ...state,
        [payload.page]: payload.path,
      };

      localStorage.setItem('nav', JSON.stringify(newNav));
      return newNav;

    default:
      return state;
  }
};

export default usersReducer;
