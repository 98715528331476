import React, { useEffect, useState } from 'react';
import styles from './Pagination.module.scss';
import SortArrow from 'components/svg/Arrow';
import { Select } from '../ui';
const limitOptions = [
  {
    value: 50,
    text: 50,
  },
  {
    value: 100,
    text: 100,
  },
  {
    value: 200,
    text: 200,
  },
];

const Pagination = ({ offset, limit, total, onOffsetChange, onLimitChange, hideLimit }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [curPage, setCurPage] = useState(0);
  const [buttonDisable, setButtonDisable] = useState({
    fist: true,
    prev: true,
    next: true,
    last: true,
  });

  useEffect(() => {
    const totalNew = Math.ceil(total / limit);
    const curPageNew = Math.ceil(offset / limit + 1);
    setTotalPages(totalNew);
    setCurPage(curPageNew);

    setButtonDisable({
      fist: curPageNew === 1,
      prev: curPageNew === 1,
      next: curPageNew === totalNew,
      last: curPageNew === totalNew,
    });
  }, [limit, total, offset]);

  const toLast = () => {
    onOffsetChange(limit * (totalPages - 1));
  };
  const toFirst = () => {
    onOffsetChange(0);
  };
  const toNext = () => {
    onOffsetChange(+offset + limit);
  };
  const toPrev = () => {
    onOffsetChange(+offset - limit);
  };

  const onInputChange = event => {
    setCurPage(event.target.value);
  };

  const onInputBlur = event => {
    const value = Number(event.target.value) || 0;
    if (value > totalPages) {
      onOffsetChange(limit * (totalPages - 1));
      setCurPage(totalPages);
      return;
    }
    if (value < 1) {
      onOffsetChange(0);
      setCurPage(1);
      return;
    }
    return onOffsetChange(limit * (value - 1));
  };

  return (
    <div className={styles.pagination}>
      {!hideLimit && (
        <Select
          className={`${styles.limit} mb-0 mr-8`}
          options={limitOptions}
          value={limit}
          onChange={onLimitChange}
        />
      )}
      <button onClick={toFirst} disabled={buttonDisable.fist} type="button" className={styles.first}>
        <SortArrow />
        <SortArrow />
      </button>
      <button onClick={toPrev} disabled={buttonDisable.prev} type="button" className={`${styles.prev}`}>
        <SortArrow />
      </button>
      <div className={styles.pages}>
        <input
          type="text"
          value={curPage}
          onChange={onInputChange}
          onBlur={onInputBlur}
          className={styles.activePage}
        />
        з&nbsp;
        {totalPages}
      </div>
      <button onClick={toNext} disabled={buttonDisable.next} type="button" className={styles.next}>
        <SortArrow />
      </button>
      <button onClick={toLast} disabled={buttonDisable.last} type="button" className={styles.last}>
        <SortArrow />
        <SortArrow />
      </button>
    </div>
  );
};

export default Pagination;
