import React from 'react';
import styles from './Navigation.module.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from 'components/svg/Home';
import Users from 'components/svg/Users';
import Courses from 'components/svg/Courses';
import Tasks from 'components/svg/Tasks';
import Modules from 'components/svg/Modules';
import Partners from 'components/svg/Partners';
import Areas from 'components/svg/Areas';
import logo from 'img/say-by-logo.png';
import { toogleMenu } from '../../store/actions/app';
import MenuResizer from '../svg/MenuResizer';
import { useDispatch } from 'react-redux';

const Navigation = () => {
  const menuOpened = useSelector(({ app }) => app.menuOpened);
  const { isAdmin } = useSelector(({ profile }) => ({
    isAdmin: profile.isAdmin,
  }));
  const nav = useSelector(({ nav }) => nav);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <nav className={`${styles.menu} ${menuOpened ? styles.menuOpened : ''}`}>
      <button className={styles.menuResizer} type="button" onClick={() => dispatch(toogleMenu())}>
        <MenuResizer />
      </button>
      <div className={styles.logo}>
        <img alt={''} className={styles.logoImg} onClick={() => history.push(`/`)} src={logo} />
      </div>
      <ul>
        <li>
          <NavLink activeClassName={styles.menuActive} exact to="/">
            <span className={styles.menuItemLogo}>
              <Home />
            </span>
            <span className={styles.menuItemText}>Галоўная</span>
          </NavLink>
        </li>
        {isAdmin && (
          <>
            <li>
              <NavLink activeClassName={styles.menuActive} to={`/tasks${nav.tasks ? '?' + nav.tasks : ''}`}>
                <span className={styles.menuItemLogo}>
                  <Tasks />
                </span>
                <span className={styles.menuItemText}>Заданні</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles.menuActive}
                to={`/modules${nav.modules ? '?' + nav.modules : ''}`}
              >
                <span className={styles.menuItemLogo}>
                  <Modules />
                </span>
                <span className={styles.menuItemText}>Модулі</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles.menuActive}
                to={`/courses${nav.courses ? '?' + nav.courses : ''}`}
              >
                <span className={styles.menuItemLogo}>
                  <Courses />
                </span>
                <span className={styles.menuItemText}>Курсы</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={styles.menuActive} to={`/areas${nav.areas ? '?' + nav.areas : ''}`}>
                <span className={styles.menuItemLogo}>
                  <Areas />
                </span>
                <span className={styles.menuItemText}>Сферы</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles.menuActive}
                to={`/partners${nav.partners ? '?' + nav.partners : ''}`}
              >
                <span className={styles.menuItemLogo}>
                  <Partners />
                </span>
                <span className={styles.menuItemText}>Партнёры</span>
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink activeClassName={styles.menuActive} to={`/users${nav.users ? '?' + nav.users : ''}`}>
            <span className={styles.menuItemLogo}>
              <Users />
            </span>
            <span className={styles.menuItemText}>Карыстальнікі</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
