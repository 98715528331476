import React from 'react';
// import styles from './BackButton.module.scss';
import { Button } from 'components/ui';
import { useHistory } from 'react-router-dom';

const BackButton = ({ beforeBack, afterBack, backAction }) => {
  const history = useHistory();
  const clickHandle = () => {
    if (beforeBack) beforeBack();
    if (backAction) {
      backAction();
    } else {
      history.goBack();
    }
    if (afterBack) afterBack();
  };
  return <Button onClick={clickHandle} type="button" text={'← Back'} />;
};

export default BackButton;
