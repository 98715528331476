import styles from './Home.module.scss';
import React, { useEffect, useState } from 'react';
import AdminDashboard from './components/AdminDashboard';
import AgentDashboard from './components/AgentDashboard';
import { useSelector } from 'react-redux';
import { Select } from 'components/ui';

const ADMIN_DASHBOARD = 'ADMIN_DASHBOARD';

const Home = () => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedView, setSelectedView] = useState(ADMIN_DASHBOARD);
  const partnersDataFromStore = useSelector(({ partners }) => partners?.data);

  const { user, isAdmin } = useSelector(({ profile }) => ({
    user: profile,
    isAdmin: profile.isAdmin,
  }));

  useEffect(() => {
    const partnersList = Object.values(partnersDataFromStore || {});
    if (isAdmin && partnersList?.length) {
      const partnersValues = partnersList.map(item => ({
        value: item.id,
        text: item.name,
      }));

      setSelectOptions([{ value: ADMIN_DASHBOARD, text: 'Агульная статыстыка' }, ...partnersValues]);
    }
  }, [isAdmin, partnersDataFromStore]);

  return (
    <div>
      <div className={styles.totalWrp}>
        {isAdmin ? (
          <>
            <Select
              options={selectOptions}
              onChange={setSelectedView}
              value={selectedView}
              className={styles.viewSelect}
            />
            {selectedView === ADMIN_DASHBOARD ? <AdminDashboard /> : <AgentDashboard id={selectedView} />}
          </>
        ) : (
          user.partner && <AgentDashboard id={user.partner} />
        )}
      </div>
    </div>
  );
};

export default Home;
