const courses = {
  apiPath: 'courses/',
  buttonNew: 'Стварыць курс',
  tableConfig: {
    name: {
      key: 'name',
      title: 'Назва',
      sortable: true,
    },
    type: {
      key: 'type',
      title: 'Тып',
      sortable: true,
    },
    updatedAt: {
      key: 'updatedAt',
      title: 'Абноўлена',
      sortable: true,
      format: 'date',
    },
    status: {
      key: 'status',
      title: 'Статус',
      sortable: true,
    },
  },
  tableConfigDND: {
    name: {
      key: 'name',
      title: 'Назва',
      sortable: true,
      class: 'main',
    },
    status: {
      key: 'status',
      title: 'Статус',
      sortable: true,
      class: 'status',
    },
  },
};

export default courses;
