import { useHistory, useParams } from 'react-router-dom';
import PagePanel from 'components/pagePanel/PagePanel';
import BackButton from 'components/backButton/BackButton';
import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui';
import api from 'config/api';
import { toast } from 'react-toastify';
import ConfirmModal from '../../components/confirmModal/ConfirmModal';
import Tabs from '../../components/tabs/Tabs';
import Trash from 'components/svg/Trash';
import PartnerLocations from 'pages/partner/PartnerLocations';
import PartnerForm from './PartnerForm';

const Partner = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [currentTab, setCurrentTab] = useState('form');
  const [partnerData, setPartnerData] = useState({});

  const { id } = useParams();
  const isNew = id === 'create';
  const history = useHistory();

  const getPartners = () => api.get(`partners/${id}`).then(({ data }) => setPartnerData(data));

  useEffect(() => {
    if (!isNew) {
      getPartners();
    }
  }, [isNew, id]);

  const onDelete = () => {
    api.delete(`partners/${id}`).then(() => {
      toast.success('Партнёр выдалена');
      history.goBack();
    });
  };
  const tabsConfig = [
    {
      name: 'Форма',
      key: 'form',
    },
  ];

  if (!isNew) {
    tabsConfig.push({
      name: 'Лакацыі',
      key: 'locations',
    });
  }

  return (
    <>
      <PagePanel>
        <BackButton />
        {id !== 'create' && (
          <Button color="Red" onClick={() => setShowConfirm(true)} text={'Выдаліць'}>
            <Trash />
          </Button>
        )}
      </PagePanel>
      <div className="page">
        <Tabs tabsConfig={tabsConfig} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        {currentTab === 'form' && <PartnerForm partnerData={partnerData} />}
        {currentTab === 'locations' && (
          <PartnerLocations partnerData={partnerData} getPartners={getPartners} />
        )}
      </div>
      {showConfirm && (
        <ConfirmModal
          onCancel={() => setShowConfirm(false)}
          onConfirm={onDelete}
          text="Вы ўпэўнены, што выдаляем?"
        />
      )}
    </>
  );
};

export default Partner;
