import { pick } from 'lodash';

export const name = 'Пералічыце прадметы на выяве. Парадак не мае значэння.';
export const nameShort = 'Пералічыце на выяве';
export const bgInTable = '#46ec54';

export const fields = [
  {
    name: 'correctAnswers',
    default: [],
    label: 'Правільныя адказы',
    component: 'ListNewValues',
  },
  {
    name: 'afterTaskExplanation',
    default: '',
    label: 'Тлумачэнне задання',
    component: 'Textarea',
  },
  {
    name: 'textContent',
    default: '',
    label: 'Змест задання',
    component: 'Input',
  },
  {
    name: 'images',
    default: '',
    label: 'Малюнкі',
    component: 'ImagesInput',
  },
];

export const dataToSend = form => {
  return {
    type: 'list_items_aloud',
    name: form.name,
    tip: form.tip,
    status: form.status,
    additionalData: {
      afterTaskExplanation: form.afterTaskExplanation,
      textContent: form.textContent,
    },
    correctAnswers: form.correctAnswers,
    images: form.images,
  };
};

export const parseData = data => {
  const dataFast = pick(data, ['name', 'status', 'images', 'tip', 'correctAnswers']);
  const { afterTaskExplanation, minimumCorrectAnswers, textContent } = data.additionalData;
  return {
    ...dataFast,
    afterTaskExplanation,
    minimumCorrectAnswers,
    textContent,
  };
};
