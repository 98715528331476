import React from 'react';
import global from '../UIGeneral.module.scss';

const Input = ({
  type = 'text',
  error = '',
  label,
  hideErrors,
  className,
  placeholder,
  noWrap,
  value,
  disabled,
  readOnly,
  onChange = () => {},
  onFocus = () => {},
  onKeyUp = () => {},
  onClick = () => {},
  onBlur = () => {},
}) => {
  const onChangeLocal = event => {
    let value = event.target.value;
    if (type === 'number') {
      value = +value;
    }
    onChange(value);
  };

  const body = () => {
    return (
      <>
        {label && <label className={global.label}>{label}</label>}
        <input
          value={value || ''}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          onChange={type === 'datePicker' ? onChange : onChangeLocal}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          onClick={onClick}
          onBlur={onBlur}
        />
        {hideErrors && <span className={global.error}>{error}</span>}
      </>
    );
  };

  return noWrap ? body() : <div className={`${className} ${global.wrp}`}>{body()}</div>;
};

export default Input;
