import React from 'react';
import StatusModal from '../filterModals/StatusModal';
import StatusPartnersModal from '../filterModals/StatusPartnersModal';
import TypeTaskModal from '../filterModals/TypeTaskModal';
import TypeCourseModal from '../filterModals/TypeCourseModal';

const ShowFilter = props => {
  const { field, type } = props;
  if (!field && !type) return null;
  if (field.key === 'status' && ['tasks', 'modules', 'areas', 'courses'].includes(type)) {
    return <StatusModal {...props} />;
  }
  if (field.key === 'status' && type === 'partners') {
    return <StatusPartnersModal {...props} />;
  }
  if (field.key === 'type' && type === 'tasks') {
    return <TypeTaskModal {...props} />;
  }
  if (field.key === 'type' && type === 'courses') {
    return <TypeCourseModal {...props} />;
  }
  return null;
};

export default ShowFilter;
