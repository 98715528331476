import styles from '../Home.module.scss';
import properWordEnd from 'utils/words-ends';
import { useEffect, useState } from 'react';
import api from 'config/api';

import { tasksWord, modulesWord, coursesWord, areaWord, partnersWord, usersWord } from 'utils/wordForm';

const AdminDashboard = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    api.get('dashboard').then(({ data }) => {
      setData(data);
    });
  }, []);

  return (
    <>
      <div className={styles.totalItem}>
        <p>{data.tasksCount}</p>
        <div>{properWordEnd(data.tasksCount, tasksWord)}</div>
      </div>
      <div className={styles.totalItem}>
        <p>{data.areasCount}</p>
        <div>{properWordEnd(data.areasCount, areaWord)}</div>
      </div>
      <div className={styles.totalItem}>
        <p>{data.coursesCount}</p>
        <div>{properWordEnd(data.coursesCount, coursesWord)}</div>
      </div>
      <div className={styles.totalItem}>
        <p>{data.partnersCount}</p>
        <div>{properWordEnd(data.partnersCount, partnersWord)}</div>
      </div>
      <div className={styles.totalItem}>
        <p>{data.submodulesCount}</p>
        <div>{properWordEnd(data.submodulesCount, modulesWord)}</div>
      </div>
      <div className={styles.totalItem}>
        <p>{data.usersCount}</p>
        <div>{properWordEnd(data.usersCount, usersWord)}</div>
      </div>
    </>
  );
};

export default AdminDashboard;
