import { useParams } from 'react-router-dom';
import api from '../../config/api';
import React, { useEffect, useState } from 'react';
import PagePanel from 'components/pagePanel/PagePanel';
import BackButton from 'components/backButton/BackButton';
import UserForm from './UserForm';
import Tabs from '../../components/tabs/Tabs';
import UserStatistic from './UserStatistic';

const User = () => {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [certificates, setCertificates] = useState();
  const [currentTab, setCurrentTab] = useState('statistic');
  const { id } = useParams();

  useEffect(() => {
    const loadData = async () => {
      await api.get(`users/${id}`).then(({ data }) => {
        return setUserData(data);
      });
      await api.get(`certificates?filter[user]=${id}`).then(({ data }) => {
        setCertificates(data.data);
      });
      setLoading(false);
    };
    return loadData();
  }, [id]);

  const tabsConfig = [
    {
      name: 'Статыстыка',
      key: 'statistic',
    },
    {
      name: 'Форма',
      key: 'form',
    },
  ];

  if (loading) {
    return (
      <div className="pageLoading">
        <div className="spinner" />
        Loading
      </div>
    );
  }

  return (
    <>
      <PagePanel>
        <BackButton />
      </PagePanel>
      <div className="page">
        <Tabs tabsConfig={tabsConfig} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        {currentTab === 'form' && <UserForm user={userData} />}
        {currentTab === 'statistic' && <UserStatistic certificates={certificates} userData={userData} />}
      </div>
    </>
  );
};

export default User;
