import { useHistory, useParams } from 'react-router-dom';
import PagePanel from 'components/pagePanel/PagePanel';
import BackButton from 'components/backButton/BackButton';
import React, { useEffect, useState } from 'react';
import {
  Input,
  Textarea,
  RadioGroup,
  ListDND,
  Button,
  RadioButton,
  Switcher,
  ImagesInput,
} from 'components/ui';
import api from 'config/api';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import ElementsBlock from '../components/ui/elementsBlock/ElementsBlock';
import { submodules } from '../config/pages';
import ConfirmModal from '../components/confirmModal/ConfirmModal';
import Trash from '../components/svg/Trash';

const Course = () => {
  const history = useHistory();
  const { id } = useParams();
  const isNew = id === 'create';
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [form, setForm] = useState({
    name: '',
    nameTag: '',
    completionText: '',
    description: '',
    image: '',
    status: 'draft',
    type: 'educational',
    submodules: [],
    isPublic: false,
    isInternalCommunication: false,
    isOnlyForExternalSystem: false,
    isExternalCommunication: false,
  });

  const setFormValue = (value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const setFormModules = (value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!isNew) {
      api.get(`courses/${id}`).then(({ data }) => {
        const dataForm = pick(data, [
          'name',
          'nameTag',
          'area',
          'completionText',
          'image',
          'description',
          'status',
          'type',
          'submodules',
          'isInternalCommunication',
          'isExternalCommunication',
          'isPublic',
        ]);
        dataForm.area = dataForm.area?.id;
        setForm(dataForm);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [isNew, id]);

  const onSubmit = () => {
    const formToSend = { ...form };
    if (!formToSend.image) {
      delete formToSend.image;
    }
    const submodules = form.submodules.map(item => item.id);
    setSaving(true);
    const afterSave = () => {
      toast.success(isNew ? 'Курс створаны' : 'Курс захаваны');
    };

    const dataToSend = {
      ...formToSend,
      submodules,
    };

    isNew
      ? api
          .post(`courses`, dataToSend)
          .then(afterSave)
          .finally(() => setSaving(false))
      : api
          .patch(`courses/${id}`, dataToSend)
          .then(afterSave)
          .finally(() => setSaving(false));
  };

  const onDelete = () => {
    api.delete(`courses/${id}`).then(() => {
      toast.success('Курс выдалены');
      history.goBack();
    });
  };

  if (loading) return false;

  return (
    <>
      <PagePanel>
        <BackButton />
        {id !== 'create' && (
          <Button color="Red" onClick={() => setShowConfirm(true)} text={'Выдаліць'}>
            <Trash />
          </Button>
        )}
      </PagePanel>
      <div className="page">
        <Input label="Назва курса*" value={form.name} onChange={e => setFormValue(e, 'name')} />
        <Input label="nameTag*" value={form.nameTag} onChange={e => setFormValue(e, 'nameTag')} />
        <Input label="area*" value={form.area} onChange={e => setFormValue(e, 'area')} />

        <ElementsBlock className={'d-flex flex-flow-wrap'}>
          <Switcher
            name="isInternalCommunication"
            className={'width-50'}
            value={form.isInternalCommunication}
            onChange={e => setFormValue(e, 'isInternalCommunication')}
            label="Унутраняя камунікацыя"
          />
          <Switcher
            name="isExternalCommunication"
            className={'width-50'}
            value={form.isExternalCommunication}
            onChange={e => setFormValue(e, 'isExternalCommunication')}
            label="Знешняя камунікацыя"
          />
          <Switcher
            name="isPublic"
            className={'width-50'}
            value={form.isPublic}
            onChange={e => setFormValue(e, 'isPublic')}
            label="Публічны"
          />
          <Switcher
            name="isOnlyForExternalSystem"
            className={'width-50'}
            value={form.isOnlyForExternalSystem}
            onChange={e => setFormValue(e, 'isOnlyForExternalSystem')}
            label="isOnlyForExternalSystem"
          />
        </ElementsBlock>
        <div className={'d-flex'}>
          <RadioGroup
            line={true}
            className={'width-50'}
            name="status"
            label="Статус"
            value={form.status}
            onChange={e => setFormValue(e, 'status')}
          >
            <RadioButton label="чарнавік" value="draft" />
            <RadioButton label="апублікаваны" value="published" />
          </RadioGroup>

          <RadioGroup
            line={true}
            className={'width-50'}
            name="type"
            value={form.type}
            label="Тып"
            onChange={e => setFormValue(e, 'type')}
          >
            <RadioButton label="навучальны" value="educational" />
            <RadioButton label="кантрольны" value="certification" />
          </RadioGroup>
        </div>

        <ImagesInput
          type={'single'}
          label="Малюнак"
          value={form.image}
          onChange={e => setFormValue(e, 'image')}
        />

        <Textarea
          label="Апісанне перад праходжаннем"
          value={form.description}
          onChange={e => setFormValue(e, 'description')}
        />
        <Textarea
          label="Апісанне пасля праходжання"
          value={form.completionText}
          onChange={e => setFormValue(e, 'completionText')}
        />
        <ListDND
          type="modules"
          label="Модулі"
          apiPath={submodules.apiPath}
          tableConfig={submodules.tableConfigDND}
          value={form.submodules}
          onChange={value => setFormModules(value, 'submodules')}
        />

        <div className="d-flex flex-end">
          <Button loading={saving} onClick={onSubmit} text={isNew ? 'Стварыць' : 'Захаваць'} />
        </div>
      </div>

      {showConfirm && (
        <ConfirmModal
          onCancel={() => setShowConfirm(false)}
          onConfirm={onDelete}
          text="Вы ўпэўнены, што выдаляем?"
        />
      )}
    </>
  );
};

export default Course;
