import { SET_PERMISSIONS, SET_PROFILE, SET_AGENT_PARTNER } from '../actionTypes';
const initialState = {};

const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PERMISSIONS:
    case SET_PROFILE:
      return (
        {
          ...state,
          ...payload,
          partnerInfo: {
            ...state.partnerInfo,
          },
        } || initialState
      );
    case SET_AGENT_PARTNER:
      return {
        ...state,
        partnerInfo: {
          ...state.partnerInfo,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default usersReducer;
