import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'components/ui';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../config/api';

const UserForm = ({ user }) => {
  const partnersFromStore = useSelector(({ partners }) => partners.data);
  const [locations, setLocations] = useState([]);
  const [saving, setSaving] = useState(false);

  const { isAdmin, isAgent, profile } = useSelector(({ profile }) => ({
    isAdmin: profile.isAdmin,
    isAgent: profile.isAgent,
    profile,
  }));

  const { position, location, partner } = user;

  const defaultValue = {
    position,
    location: location?.id || '',
    ...(isAdmin && { partner }),
  };

  const [form, setForm] = useState(defaultValue);

  useEffect(() => {
    let newLocations = [
      {
        id: '',
        name: 'Без лакацыі',
      },
    ];
    if (isAdmin) {
      newLocations = newLocations.concat(partnersFromStore[form.partner]?.locations);
    }
    if (isAgent) {
      newLocations = newLocations.concat(profile.partnerInfo.locations);
    }

    setLocations(newLocations);

    if (!newLocations.find(loc => loc.id === form.location)) {
      setFormValue(newLocations?.[0]?.id, 'location');
    }
  }, [form.partner]);

  const setFormValue = (value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    setSaving(true);
    if (!form.location) {
      form.location = null;
    }
    api
      .patch(`users/${user.id}`, form)
      .then(() => {
        toast.success('Карыстальнік абноўлены');
      })
      .catch(err => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => setSaving(false));
  };

  return (
    <>
      {isAdmin && (
        <Select
          label="Партнёр"
          textField={'name'}
          valueField={'id'}
          options={Object.values(partnersFromStore)}
          onChange={e => setFormValue(e, 'partner')}
          value={form.partner}
        />
      )}
      <Select
        label="Лакацыя"
        textField={'name'}
        valueField={'id'}
        options={locations}
        onChange={e => setFormValue(e, 'location')}
        value={form.location}
      />
      <Input label="Пазіцыя" value={form.position} onChange={e => setFormValue(e, 'position')} />

      <div className="d-flex flex-end">
        <Button loading={saving} onClick={onSubmit} text={'Захаваць'} />
      </div>
    </>
  );
};

export default UserForm;
