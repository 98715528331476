import React from 'react';
import { Button } from '../index';
import styles from './AudioInput.module.scss';
import global from '../UIGeneral.module.scss';
import Pencil from '../../svg/Pencil';
import api from '../../../config/api';
import { toast } from 'react-toastify';

const AudioInput = ({ value, label, onChange, type }) => {
  const deleteItem = index => {
    const newValue = { ...value };
    delete newValue[index];
    onChange(newValue);
  };
  const handleChange = (e, index) => {
    const file = e?.target?.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      api
        .post(`media/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          onChange({
            ...value,
            [index]: res.data.url,
          });
        })
        .catch(() => {
          toast.error('Памылка загрузкі Аўдыё', {
            autoClose: 5000,
          });
        });
    }
  };
  return (
    <div className={global.wrp}>
      {label && <label className={global.label}>{label}</label>}
      <div className={styles.audiosWrp}>
        {value &&
          Object.entries(value).map((item, index) => {
            return (
              <div className={styles.audioRow} key={'audio' + item[0]}>
                <div className={styles.audioWrp}>
                  <audio controls className={styles.audio} src={item[1]} />
                  <div className={styles.audioChange}>
                    <div className={styles.audioPencil}>
                      {' '}
                      <Pencil />{' '}
                    </div>
                    <input className={styles.audioFile} type="file" onChange={e => handleChange(e, index)} />
                  </div>
                  <button className={styles.deleteItem} type="button" onClick={() => deleteItem(item[0])}>
                    X
                  </button>
                </div>
              </div>
            );
          })}
        <div className={styles.audioRow} key={'audio'}>
          <div className={styles.audioWrp}>
            <input
              className={styles.audioFile}
              type="file"
              onChange={e => handleChange(e, Object.keys(value).length + 1)}
            />
            <Button text="Дадаць" className={styles.audioNew} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioInput;
