import React, { useEffect, useState } from 'react';
import { Input, Button } from 'components/ui';
import api from 'config/api';
import styles from './Partner.module.scss';
import Pencil from '../../components/svg/Pencil';

const PartnerLocations = ({ partnerData, getPartners }) => {
  const [saving, setSaving] = useState(false);
  const { locations } = partnerData;

  const defaultForm = {
    name: '',
    nameTag: '',
    address: '',
    phoneNumber: '',
  };
  const [form, setForm] = useState(defaultForm);

  useEffect(() => {
    setForm(partnerData);
  }, [partnerData.id]);

  const setFormValue = (value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    setSaving(true);
    const afterSave = () => {
      getPartners();
      setForm(defaultForm);
      // toast.success(isNew ? 'Партнёр створаны' : 'Партнёр захаваны');
    };

    api
      .post(`partners/${partnerData.id}/locations`, form)
      .then(data => {
        console.log(data);
        afterSave();
      })
      .finally(() => setSaving(false));
  };

  const editItem = () => {};
  const deleteItem = () => {};

  return (
    <>
      {!!locations.length && (
        <table className={`${styles.table} mainTable`}>
          <tbody>
            <tr className="mainTableHead">
              <td>Назва</td>
              <td>Адрас</td>
              <td>Нумар тэлефона</td>
              <td />
            </tr>
            {locations.map((row, index) => (
              <tr key={index}>
                <td>{row.name}</td>
                <td>{row.address}</td>
                <td>{row.phoneNumber}</td>
                <td className={styles.actionTD}>
                  <button className={styles.editItem} type="button" onClick={() => editItem(index)}>
                    <Pencil />
                  </button>
                  <button className={styles.deleteItem} type="button" onClick={() => deleteItem(index)}>
                    X
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Input label="Назва*" value={form.name} onChange={e => setFormValue(e, 'name')} />
      <Input label="Адмысловае імя*" value={form.nameTag} onChange={e => setFormValue(e, 'nameTag')} />
      <Input label="Адрас*" value={form.address} onChange={e => setFormValue(e, 'address')} />
      <Input
        label="Нумар тэлефона*"
        value={form.phoneNumber}
        onChange={e => setFormValue(e, 'phoneNumber')}
      />
      <div className="d-flex flex-end">
        <Button loading={saving} onClick={onSubmit} text={'Стварыць'} />
      </div>
    </>
  );
};

export default PartnerLocations;
