import React from 'react';
import styles from './Tabs.module.scss';
import { Button } from '../ui';

const Tabs = ({ tabsConfig, currentTab, setCurrentTab }) => {
  return (
    <div className={styles.tabs}>
      {tabsConfig.map((item, index) => (
        <Button
          key={item.key}
          color={null}
          className={`${styles.tab}  ${currentTab === item.key ? styles.tabActive : ''}`}
          onClick={() => setCurrentTab(item.key)}
          text={item.name}
        />
      ))}
    </div>
  );
};

export default Tabs;
