import React from 'react';
import styles from './Checkbox.module.scss';
import global from '../UIGeneral.module.scss';

const Checkbox = ({ label, name, value, className, noWrap, insideBlock, onChange = () => {} }) => {
  const onChangeLocal = event => {
    onChange(event.target.checked);
  };

  const body = () => {
    return (
      <>
        <input
          checked={value}
          onChange={onChangeLocal}
          className={styles.checkbox}
          type="checkbox"
          value={value}
          id={name}
        />
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      </>
    );
  };

  return noWrap ? (
    body()
  ) : (
    <div className={`${insideBlock ? 'mb-8' : global.wrp} ${className}`}>{body()}</div>
  );
};

export default Checkbox;
