import { pick } from 'lodash';

export const name = 'Размова: Прачытаць тэкст услых';
export const nameShort = 'Прачытаць тэкст услых';
export const bgInTable = '#FF8C00';

export const fields = [
  {
    name: 'correctAnswers',
    default: [],
    label: 'Правільныя адказы',
    component: 'ListNewValues',
  },
  {
    name: 'afterTaskExplanation',
    default: '',
    label: 'Тлумачэнне задання',
    component: 'Textarea',
  },
  {
    name: 'textContent',
    default: '',
    label: 'Змест задання',
    component: 'Input',
  },
];

export const dataToSend = form => {
  return {
    type: 'read_aloud',
    name: form.name,
    tip: form.tip,
    status: form.status,
    additionalData: {
      afterTaskExplanation: form.afterTaskExplanation,
      textContent: form.textContent,
    },
    correctAnswers: form.correctAnswers,
  };
};

export const parseData = data => {
  const dataFast = pick(data, ['name', 'status', 'tip', 'correctAnswers']);
  const { afterTaskExplanation, textContent } = data.additionalData;
  return {
    ...dataFast,
    afterTaskExplanation,
    textContent,
  };
};
