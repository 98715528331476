export const date = dateTimeStamp => {
  const date = new Date(dateTimeStamp);
  if (!dateTimeStamp) return '';
  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }

  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}.${month}.${date.getFullYear()}`;
};

export const dateISO = date => {
  if (!date) return '';
  const dateData = date.split('.');
  return new Date(`${dateData[1]}/${dateData[0]}/${dateData[2]}`).toISOString();
};
