import React from 'react';
import PagePanel from 'components/pagePanel/PagePanel';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'components/ui';
import Table from '../../components/table/Table';
import Plus from '../../components/svg/Plus';

const TablePage = ({ apiPath, buttonNew, tableConfig, type, showPagePanel = true }) => {
  const history = useHistory();
  const match = useRouteMatch();

  const edit = row => history.push(`${match.path}/${row.id}`);
  const create = () => history.push(`${match.path}/create`);

  return (
    <>
      {showPagePanel && (
        <PagePanel>
          {buttonNew && (
            <Button onClick={create} text={buttonNew}>
              <Plus />
            </Button>
          )}
        </PagePanel>
      )}
      <div className={`page ${showPagePanel ? '' : 'mt-32'}`}>
        <Table type={type} onRowClick={edit} apiPath={apiPath} tableConfig={tableConfig} />
      </div>
    </>
  );
};

export default TablePage;
