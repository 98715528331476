import React from 'react';
import styles from './ContentBar.module.scss';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ContentBar = ({ showSearchContentBar = true }) => {
  const { logout } = useAuth0();

  const profile = useSelector(({ profile }) => profile);

  const logoutHandle = () => {
    logout({ returnTo: window.location.origin });
  };

  const photoURL = profile?.picture;
  return (
    <div className={styles.contentBar}>
      {showSearchContentBar && (
        <div className={styles.pageSearch}>
          <input type="text" placeholder="Search" />
        </div>
      )}

      <div className={styles.userLogo}>
        <div className={styles.userLogoImg} style={{ backgroundImage: `url(${photoURL})` }}>
          {photoURL ? '' : profile?.email && String(profile.email).substring(0, 1)}
        </div>
        <div className={styles.userLogoMenu}>
          <NavLink activeClassName={styles.menuActive} exact to="/profile">
            Профіль
          </NavLink>
          {/*<NavLink activeClassName={styles.menuActive} exact to="/company">*/}
          {/*  Кампанія*/}
          {/*</NavLink>*/}

          <button type="button" onClick={logoutHandle}>
            Выйсці
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentBar;
