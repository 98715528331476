import rootReducer from './reducers/rootReducer';
import { applyMiddleware, compose, createStore } from 'redux';

import thunk from 'redux-thunk';

const middlewares = [thunk];

// React DevTools extension
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? undefined;
const addReactDevTools =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = addReactDevTools(applyMiddleware(...middlewares));
const store = createStore(rootReducer, enhancer);

export default store;
