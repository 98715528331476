import { pick } from 'lodash';

export const name = 'Паслухайце, і абярыце прадмет на карцінке, што адпавядае';
export const nameShort = 'Абярыце на карцінке';
export const bgInTable = '#1befbe';

export const fields = [
  {
    name: 'correctAnswers',
    default: [],
    label: 'Правільныя адказы',
    component: 'ListNewValues',
  },
  {
    name: 'afterTaskExplanation',
    default: '',
    label: 'Тлумачэнне задання',
    component: 'Textarea',
  },
  {
    name: 'textContent',
    default: '',
    label: 'Змест задання',
    component: 'Input',
  },
  {
    name: 'audio',
    default: '',
    label: 'Аўдыёзапісы',
    component: 'AudioInput',
  },
  {
    name: 'options',
    default: [],
    label: 'options',
    component: 'ListNewValues',
  },
  {
    name: 'images',
    default: '',
    label: 'Малюнкі',
    component: 'ImagesInput',
  },
];

export const dataToSend = form => {
  const optionsResult = form.options.reduce((acc, el, index) => {
    acc[index + 1] = el;
    return acc;
  }, {});

  return {
    type: 'listen_choose_picture',
    name: form.name,
    tip: form.tip,
    status: form.status,
    additionalData: {
      afterTaskExplanation: form.afterTaskExplanation,
      textContent: form.textContent,
      options: optionsResult,
    },
    correctAnswers: form.correctAnswers,
    audio: form.audio,
    images: form.images,
  };
};

export const parseData = data => {
  const dataFast = pick(data, ['name', 'status', 'audio', 'images', 'tip', 'correctAnswers']);
  const { afterTaskExplanation, minimumCorrectAnswers, textContent, options } = data.additionalData;
  return {
    ...dataFast,
    afterTaskExplanation,
    minimumCorrectAnswers,
    options: Object.values(options || {}),
    textContent,
  };
};
