const translations = {
  draft: 'Чарнавік',
  published: 'Апублікаваны',
  active: 'Актыўны',
  inactive: 'Не актыўны',
  educational: 'Навучальны',
  certification: 'Кантрольны',
  passed: 'Пройдзены',
  failed: 'Не пройдзены',
};

export default translations;
