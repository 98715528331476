import React, { useState } from 'react';
import { Button, Textarea } from '../index';
import styles from './listNewValues.module.scss';
import global from '../UIGeneral.module.scss';
import Pencil from '../../svg/Pencil';

const ListNewValues = ({ list = [], setValue, label }) => {
  const [newValue, setNewValue] = useState(null);
  const [newValueIndex, setNewValueIndex] = useState(null);

  const addNewValue = () => {
    const newList = [...list];
    if (newValueIndex === null) {
      newList.splice(newValueIndex, 0, newValue);
    } else {
      newList.splice(newValueIndex, 1, newValue);
    }

    setValue(newList);
    setNewValue('');
    setNewValueIndex(null);
  };
  const deleteItem = index => {
    const newList = [...list];
    newList.splice(index, 1);
    setValue(newList);
  };

  const editItem = index => {
    setNewValueIndex(index);
    setNewValue(list[index]);
  };

  return (
    <div className={global.wrp}>
      {label && <label className={global.label}>{label}</label>}
      <div className={styles.inputWrp}>
        <Textarea
          className={styles.addTextarea}
          value={newValue}
          onChange={e => setNewValue(e)}
          height={'40px'}
        />
        <Button
          onClick={addNewValue}
          type="button"
          disabled={newValue === ''}
          text={newValueIndex === null ? 'Дадаць' : 'Захаваць'}
          className={styles.addButton}
        />
      </div>
      {list.map((item, index) => (
        <div key={index} className={styles.listItem}>
          <pre>{item}</pre>
          <button className={styles.editItem} type="button" onClick={() => editItem(index)}>
            <Pencil />
          </button>
          <button className={styles.deleteItem} type="button" onClick={() => deleteItem(index)}>
            X
          </button>
        </div>
      ))}
    </div>
  );
};

export default ListNewValues;
