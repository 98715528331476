const users = ({ isAdmin }) => ({
  apiPath: 'users/',
  buttonNew: '',
  showPagePanel: false,
  tableConfig: {
    email: {
      key: 'email',
      title: 'Email',
      sortable: true,
    },
    location: {
      key: 'location.name',
      title: 'Лакацыя',
      sortable: false,
    },
    position: {
      key: 'position',
      title: 'Пазіцыя',
      sortable: false,
    },
    ...(isAdmin && {
      partner: {
        key: 'partner',
        title: 'Партнёр',
        sortable: true,
      },
    }),
    updatedAt: {
      key: 'updatedAt',
      title: 'Абноўлена',
      sortable: true,
      format: 'date',
    },
    createdAt: {
      key: 'createdAt',
      title: 'Створана',
      sortable: true,
      format: 'date',
    },
  },
});

export default users;
